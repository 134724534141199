import React, { Suspense } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { hot } from 'react-hot-loader/root';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Waiter } from 'react-wait';
import lightTheme from '@targetable/targetable-web-framework/lib/themes/light';
import GoogleMapsLoadScript from '@targetable/targetable-web-framework/lib/react/components/GoogleMapsLoadScript/GoogleMapsLoadScript';
import { GlobalsProvider } from '@targetable/targetable-web-framework/lib/context/globals';
import { ReduxActionProvider } from '@targetable/targetable-web-framework/lib/context/actions';
import { ReduxSelectorProvider } from '@targetable/targetable-web-framework/lib/context/selectors';
import { getLoaderString } from '@targetable/targetable-web-framework/lib/services/loaders_copy';

import globals from '../../globals';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import history from '../../services/history';

import loadingImg from '../../static/images/component_loading_animation.gif';
import FacebookPermissionsModal from '../FacebookPermissionsModal/FacebookPermissionsModal';
import FeedbackDialog from '../FeedbackDialog/FeedbackDialog';
import InfoStepperConnectAdAccount from '../InfoStepperConnectAdAccount/InfoStepperConnectAdAccount';
import InfoStepperConnectBM from '../InfoStepperConnectBM/InfoStepperConnectBM';
import InstagramSelectModal from '../InstagramSelectModal/InstagramSelectModal';
import GeneralConfirmationDialog from '../GeneralConfirmationDialog/GeneralConfirmationDialog';
import AddBusinessModal from '../AddBusinessModal/AddBusinessModal';
import AddBusinessConfirmModal from '../AddBusinessConfirmModal/AddBusinessConfirmModal';
import AddStoreModal from '../AddStoreModal/AddStoreModal';
import FacebookAntiScumModal from '../FacebookAntiScumModal/FacebookAntiScumModal';
import EditBusinessNameModal from '../EditBusinessNameModal/EditBusinessNameModal';
import EditStoreNameModal from '../EditStoreNameModal/EditStoreNameModal';
import FacebookPageSelectModal from '../FacebookPageSelectModal/FacebookPageSelectModal';
import BillingModal from '../BillingModal/components/BillingModal/BillingModal';
import AdAccountSelectModal from '../AdAccountSelectModal/AdAccountSelectModal';
import ModalWrapper from '../ModalWrapper/ModalWrapper';
import Router from '../Router/Router';
import store from '../../store';
import Toaster from '../Toaster/Toaster';
import Backdrop from '../Backdrop/Backdrop';
import PublishMarketingDialog from '../PublishMarketingDialog/PublishMarketingDialog';

const fallback = () => (
  <>
    <div style={{ width: '100%', textAlign: 'center' }}>
      <img src={loadingImg} alt="Loading" />
    </div>
    <Typography align="center" variant="h3" style={{ textTransform: 'uppercase' }}>
      {getLoaderString()}
    </Typography>
  </>
);

const App = () => (
  <GlobalsProvider value={globals}>
    <ReduxActionProvider value={actions}>
      <ReduxSelectorProvider value={selectors}>
        <Provider store={store}>
          <MuiThemeProvider theme={lightTheme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Waiter>
                <Suspense fallback={fallback()}>
                  <CssBaseline />
                  <ConnectedRouter history={history}>
                    <GoogleMapsLoadScript fallback={fallback()}>
                      <Router />
                      <Toaster />
                      <ModalWrapper
                        modal={EditBusinessNameModal}
                        selector={selectors.selectShowEditBusinessNameModal}
                      />
                      <ModalWrapper
                        modal={EditStoreNameModal}
                        selector={selectors.selectShowEditStoreNameModal}
                      />
                      <FacebookAntiScumModal />
                      <AddBusinessModal />
                      <AddBusinessConfirmModal />
                      <AddStoreModal />
                      <FacebookPageSelectModal />
                      <AdAccountSelectModal />
                      <FeedbackDialog />
                      <InfoStepperConnectBM />
                      <InfoStepperConnectAdAccount />
                      <FacebookPermissionsModal />
                      <InstagramSelectModal />
                      <GeneralConfirmationDialog />
                      <PublishMarketingDialog />
                      <BillingModal />
                      <Backdrop />
                    </GoogleMapsLoadScript>
                  </ConnectedRouter>
                </Suspense>
              </Waiter>
            </MuiPickersUtilsProvider>
          </MuiThemeProvider>
        </Provider>
      </ReduxSelectorProvider>
    </ReduxActionProvider>
  </GlobalsProvider>
);

export default hot(App);
