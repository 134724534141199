/* eslint-disable max-len */

import _ from 'lodash';
import {
  validateCampaign,
  getCampaignStores,
} from '@targetable/targetable-web-framework/lib/react/campaignWizard/services/campaign/campaign';
import { MarketingChannelTypes } from '@targetable/targetable-types/dist/types/marketing-channel/types';
// eslint-disable-next-line no-unused-vars
import { TargetableBusiness } from '@targetable/targetable-types/dist/types/business';

/**
 * @returns {TargetableBusiness}
 */
export const selectBusiness = (state) => state.business.business;
export const selectBusinessLoading = (state) => _.get(state, ['business', 'businessLoading'], false);
export const selectBusinessInsights = (state) => state.business.insights;
export const selectBusinessInsightsLoading = (state) => state.business.insightsLoading;
export const selectBusinessUsers = (state) => state.business.users;
export const selectBusinessUsersLoading = (state) => state.business.usersLoading;
export const selectBusinessSuggestedCampaigns = (state) => state.business.suggestedCampaigns;
export const selectBusinessSuggestedCampaignsLoading = (state) => state.business.suggestedCampaignsLoading;
export const selectBusinessStores = (state) => _.get(state, 'stores.stores', []);

export const selectCampaign = (state) => state.campaign;
export const selectCampaignStatus = (state) => _.get(state, 'campaign.status');
export const selectCampaignType = (state) => _.get(state, 'campaign.type', MarketingChannelTypes.Facebook);
export const selectCampaignName = (state) => _.get(state, 'campaign.name');
export const selectCampaignGoal = (state) => _.get(state, 'campaign.goal');
export const selectCampaignObjective = (state) => _.get(state, 'campaign.objective');
export const selectCampaignOverrideAutoAging = (state) => _.get(state, 'campaign.overrideAutoAging');
export const selectCampaignAdItem = (state) => _.get(state, 'campaign.ads.0');
export const selectCampaignAd = (state) => _.get(state, 'campaign.ads.0.ad');
export const selectCampaignAds = (state) => _.get(state, 'campaign.ads');
export const selectCampaignAdCards = (state) => _.get(state, 'campaign.ads.0.ad.adCards');
export const selectCampaignAdCTA = (state) => _.get(state, 'campaign.ads.0.ad.callToAction');
export const selectCampaignBudget = (state) => _.get(state, 'campaign.ads.0.budget');
export const selectCampaignCustomAudiences = (state) => _.get(state, 'campaign.ads.0.targeting.customAudiences');
export const selectCampaignExisting = (state) => !!state.campaign.id;
export const selectCampaignGender = (state) => _.get(state, 'campaign.ads.0.targeting.gender');
export const selectCampaignMinAge = (state) => _.get(state, 'campaign.ads.0.targeting.minAge');
export const selectCampaignMaxAge = (state) => _.get(state, 'campaign.ads.0.targeting.maxAge');
export const selectCampaignSchedule = (state) => _.get(state, 'campaign.ads.0.schedule');
export const selectCampaignSpecificTargets = (state) => _.get(state, 'campaign.ads.0.targeting.specificTargets');
export const selectCampaignStoreCount = (state) => _.get(state, 'campaign.ads', []).length;
export const selectCampaignStores = (state) => getCampaignStores(
  _.get(state, 'stores.stores', []),
  _.get(state, 'campaign.ads', []),
);
export const selectCampaignValid = (state) => validateCampaign(
  state.campaign,
  selectCampaignStores(state),
);
export const selectCampaignTargeting = (state) => _.get(state, 'campaign.ads.0.targeting');
export const selectCampaignMetrics = (state) => state.facebookMetrics.campaign;
export const selectCampaignTrends = (state) => state.facebookMetrics.trends;
export const selectCampaignMetricsLoading = (state) => state.facebookMetrics.campaignLoading;
export const selectCampaignMetricsLoadingError = (state) => state.facebookMetrics.campaignLoadingError; // eslint-disable-line max-len
export const selectCampaignEventId = (state) => _.get(state, 'campaign.eventId');
export const selectCampaignLeadForms = (state) => _.get(state, 'business.leadForms');
export const selectSuggestedCampaignEventId = (state) => _.get(state, 'suggestedCampaign.eventId');

export const selectCampaigns = (state) => state.campaigns;
export const selectSuggestedCampaign = (state) => state.suggestedCampaign;
export const selectSuggestedCampaignAdType = (state) => _.get(state, 'suggestedCampaign.ads.0.ad.type');
export const selectSuggestedCampaignAdCTA = (state) => _.get(state, 'suggestedCampaign.ads.0.ad.callToAction');

export const selectFeedback = (state) => state.feedback;
export const selectInitialImages = (state) => state.images.initialImages;
export const selectInitialImagesCursor = (state) => state.images.initialCursor;
export const selectIsLoadingAccountUploads = (state) => state.images.loading;
export const selectImagesMam = (state) => state.imagesMam.imageList;
export const selectInstagramAccount = (state) => state.connectInstagram.instagramAccount;
export const selectInstagramAccounts = (state) => state.connectInstagram.instagramAccounts;
export const selectIntegrations = (state) => state.integrations;
export const selectIsSearchingMam = (state) => state.imagesMam.isSearching;
export const selectOnboardingBusiness = (state) => state.onboardingBusiness;
export const selectShowBackdrop = (state) => state.loading.backdrop;
export const selectShowInstagramSelect = (state) => state.connectInstagram.showInstagramSelect;
export const selectSearchTermMam = (state) => state.imagesMam.searchTerm;
export const selectSmartFeedItems = (state) => state.smartFeed.items;
export const selectSmartFeedLoading = (state) => state.smartFeed.loading;
export const selectStores = (state) => state.stores.stores;
export const selectStoresLoading = (state) => state.stores.storesLoading;
export const selectStoresFacebookInfoLoading = (state) => state.stores.storesFacebookInfoLoading;
export const selectToaster = (state) => state.toaster;
export const selectUser = (state) => state.user.active;
export const selectUserAllUsers = (state) => state.user.all;

export const selectBillingModalStage = (state) => state.billing.modalStage;
export const selectFacebookPaymentMethods = (state) => state.billing.facebook.paymentMethods;
export const selectShowBillingModal = (state) => state.billing.showModal;
export const selectIncludeCouponBillingModal = (state) => state.billing.includeCoupon;
export const selectIsUpdateBillingModal = (state) => state.billing.isUpdate;
export const selectSubscription = (state) => state.billing.subscription;
export const selectSubscriptionLoading = (state) => state.billing.subscriptionLoading;

export const selectFacebookPermissions = (state) => state.facebookPermissions;
export const selectFacebookTokenExpired = (state) => state.facebookPermissions.tokenExpired;
export const selectConfirmationDialogInformation = (state) => state.confirmationDialog;
export const selectPublishMarketingDialogInformation = (state) => state.publishMarketingDialog;
export const selectFacebookPages = (state) => state.facebookPages.items;
export const selectFacebookPagesLoading = (state) => state.facebookPages.loading;

export const selectShowAddBusinessModal = (state) => state.modals.showAddBusiness;
export const selectShowAddBusinessConfirmModal = (state) => state.modals.showAddBusinessConfirm;
export const selectShowAddStoreModal = (state) => state.modals.showAddStore;
export const selectShowFacebookAntiScumModal = (state) => state.modals.showFacebookAntiScum;
export const selectShowFacebookConnectAdAccountModal = (state) => state.modals.showFacebookConnectAdAccount; // eslint-disable-line max-len
export const selectShowFacebookConnectBusinessManagerModal = (state) => state.modals.showFacebookConnectBusinessManager; // eslint-disable-line max-len
export const selectShowFacebookPageSelectModal = (state) => state.modals.showFacebookPageSelect;
export const selectShowFacebookPermissionsModal = (state) => state.modals.showFacebookPermissions; // eslint-disable-line max-len
export const selectShowFacebookAdAccountSelectModal = (state) => state.modals.showFacebookAdAccountSelect; // eslint-disable-line max-len
export const selectShowEditBusinessNameModal = (state) => state.modals.showEditBusinessNameModal;
export const selectShowEditStoreNameModal = (state) => state.modals.showEditStoreNameModal.show;
export const selectStoreEditStoreNameModal = (state) => state.modals.showEditStoreNameModal.store;
export const selectShowAccountIssuesModal = (state) => state.modals.showAccountIssuesModal;

export const selectAsyncLoading = (state) => state.loading.async;
export const selectLoadingPermissions = (state) => state.loading.permissions;

export const selectReportsLoading = (state) => state.reports.loading;
export const selectCampaignReport = (state, id) => state.reports.campaignReports[id];
export const selectCampaignReports = (state) => state.reports.campaignReports;

export const selectStoresWithPaymentMethod = (state) => state.stores.storesPaymentMethods;

export const selectUrlPresets = (state) => state.urlPresets.presets;

export const selectCustomAudiences = (state) => state.storesCustomAudiences.stores;

export const selectAdCardPresets = (state) => state.adCardPresets.cards;
export const selectAdCardPreset = (state, id) => _.get(state, ['adCardPresets', 'cards', id], {});

export const selectRouterLocationPath = (state) => state.router.location.pathname || {};

export const selectPixelEventType = (state) => _.get(state, 'campaign.pixelEventType');
export const selectCampaignOptimizationGoal = (state) => _.get(state, 'campaign.optimizationGoal');

export const selectCampaignAdType = (state) => _.get(state, ['campaign', 'ads', '0', 'ad', 'type']);

/**
 * Selects the user invoice history
 * @returns {import ('@targetable/targetable-web-framework/src/services/apis/billing/typedefs.js').StripeInvoice[]} a list of invoices
 */
export const selectInvoiceHistory = (state) => state.billing.invoiceHistory.invoices;

/**
 * @returns {boolean}
 */
export const selectInvoiceHistoryLoading = (state) => state.billing.invoiceHistory.loading;

export const selectGoogleMetrics = (state) => state.googleMetrics.adMetrics;
export const selectGoogleMetricsLoading = (state) => state.googleMetrics.adMetricsLoading;
export const selectGoogleMetricsLoadingError = (state) => state.googleMetrics.adMetricsLoadingError; // eslint-disable-line max-len

export const selectGoogleSitelinks = (marketingChannelId) => (state) => _.get(state, ['googleAssets', 'sitelinks', marketingChannelId, 'data'], {});
export const selectGoogleSitelinksLoading = (marketingChannelId) => (state) => _.get(state, ['googleAssets', 'sitelinks', marketingChannelId, 'loading'], {});
export const selectGooglePromotions = (marketingChannelId) => (state) => _.get(state, ['googleAssets', 'promotions', marketingChannelId, 'data'], {});
export const selectGooglePromotionsLoading = (marketingChannelId) => (state) => _.get(state, ['googleAssets', 'promotions', marketingChannelId, 'loading'], {});
export const selectGoogleAssets = (state) => _.get(state, 'googleAssets', {});

export const selectBusinessCampaignsLoading = (state) => state.loading.campaigns;
export const selectBusinessCampaigns = (state) => state.campaigns;

export const selectBellNotifications = (state) => state.bellNotifications;

export const selectMarketingChannels = (state) => state.marketingChannels.marketingChannels;
export const selectMarketingChannelsLoading = (state) => state.marketingChannels.loading;

export const selectSearchAdCombinations = (state) => state.searchAdCombinations.adCombinations;
export const selectSearchAdCombinationsLoading = (state) => state.searchAdCombinations.adCombinationsLoading;
export const selectSearchAdCombinationsLoadingError = (state) => state.searchAdCombinations.adCombinationsLoadingError; // eslint-disable-line max-len

export const selectCoordinates = (state) => state.locations.coordinates;
export const selectLoadingCoordinates = (state) => state.locations.loadingCoordinates;

export const selectComments = (state) => state.comments.comments;
export const selectLoadingComments = (state) => state.comments.loadingComments;
