import _ from 'lodash';
import moment from 'moment';
import {
  all,
  call,
  fork,
  put,
  select,
} from 'redux-saga/effects';

import history from '../../services/history';

import { getHomeRoute, getLoadingRoute, getRejectedRoute } from '../../routes';

import { setLoadingAsync, setUserAllUsers } from '../../actions';
import {
  selectBusiness,
  selectStores,
  selectUser,
  selectUserAllUsers,
} from '../../selectors';
import logger from '../../services/logger';
import createDefaultInsights from '../createDefaultInsights/createDefaultInsights';
import createDefaultSuggestedCampaigns from '../createDefaultSuggestedCampaigns/createDefaultSuggestedCampaigns';
import createStripeCustomer from '../createStripeCustomer/createStripeCustomer';
// import createIntegrationStripe from '../createIntegrationStripe/createIntegrationStripe';
import getBusiness from '../getBusiness/getBusiness';
import getStoresByBusiness from '../getStoresByBusiness/getStoresByBusiness';
// import getStripeSubscription from '../getStripeSubscription/getStripeSubscription';
import handleStoreRegistration from '../handleStoreRegistration/handleStoreRegistration';
import handleUserRegistration from '../handleUserRegistration/handleUserRegistration';
import handleMetaRegistration from '../handleMetaRegistration/handleMetaRegistration';
// import updateBusiness from '../updateBusiness/updateBusiness';
import handleEntryAnalytics from '../handleEntryAnalytics/handleEntryAnalytics';
import updateBusiness from '../updateBusiness/updateBusiness';
import updateUser from '../updateUser/updateUser';
import api from '../../services/api';
import colors from '../../services/colors';

export default function* handleNewUser(user, hubSpotEmail, couponCode) {
  try {
    yield call(handleUserRegistration, user, hubSpotEmail);

    let businessId = _.get(user, 'businessId');

    if (!businessId) {
      yield call(handleStoreRegistration, couponCode);
    } else {
      const [business, stores] = yield all([
        call(getBusiness, businessId),
        call(getStoresByBusiness, businessId),
      ]);

      if (_.isEmpty(business) || _.isEmpty(stores)) {
        yield call(handleStoreRegistration, couponCode, _.get(business, 'id'));
      } else {
        yield call(handleStoreRegistration, couponCode);
      }
    }

    yield call(handleMetaRegistration);

    history.push(getLoadingRoute());
    const newUser = yield select(selectUser);
    const business = yield select(selectBusiness);
    const stores = yield select(selectStores);

    businessId = _.get(business, 'id');
    const userId = _.get(newUser, 'id');
    const storeId = _.get(stores, '0.id');
    const userEmail = _.get(newUser, 'email');

    try {
      yield call(api.associateUserHubspotRecordToBusinessHubspotRecord, userId);
    } catch (e) {
      logger.error({
        error: `Failed to associate new user ${userId} with business ${businessId}`,
        context: { saga: 'handleNewUser' },
        params: { user, businessId },
      });
    }

    yield call(handleEntryAnalytics, business, newUser, undefined, true);

    if (_.isNil(_.get(business, 'stripeCustomerId'))) {
      const customerId = yield call(
        createStripeCustomer, businessId, _.get(business, 'name'), userEmail,
      );
      if (customerId) {
        yield call(updateBusiness, {
          payload: {
            id: businessId,
            stripeCustomerId: customerId,
            onboarded: true,
          },
        });
      } else {
        throw new Error('Failed to create billing record');
      }
    }

    yield fork(updateUser, {
      id: userId,
      onboarded: true,
      lastLogin: moment.utc().valueOf(),
    });

    yield call(createDefaultInsights, { businessId });
    yield call(createDefaultSuggestedCampaigns, { businessId, storeId });

    yield put(setLoadingAsync(false));

    const hubspotFieldsToUpdate = { businessOnboardingCompleted: true };

    try {
      yield call(api.updateBusinessHubspotRecord, businessId, hubspotFieldsToUpdate);
    } catch (e) {
      logger.error({
        error: `Failed to update business ${businessId} hubspot record`,
        context: { saga: 'handleNewUser' },
        params: { user, businessId, ...hubspotFieldsToUpdate },
      });
    }

    // ensure we set this with our new user after everything is done
    const avatarColors = colors.getAvatarColors(1);
    let allUsers = yield select(selectUserAllUsers);

    // Add user only if it doesn't exist
    const userExists = allUsers?.find((usr) => usr.id === newUser.id);
    if (!userExists) {
      allUsers = [
        ...(allUsers || []),
        {
          ...newUser,
          backgroundColor: avatarColors[0],
        },
      ];

      // Update list
      yield put(setUserAllUsers(allUsers));
    }

    history.push(getHomeRoute(businessId));
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'handleNewUser' },
      params: { user, hubSpotEmail, couponCode },
    });
    history.push(getRejectedRoute());
  }
}
