import {
  call,
  put,
  select,
  fork,
} from 'redux-saga/effects';
import _ from 'lodash';
import { getUserFacebookIntegration } from '@targetable/targetable-web-framework/lib/services/user';

import {
  showBackdrop,
  showToaster,
  showFacebookPageSelectModal,
  setLoadingAsync,
  showFacebookAntiScumModal,
} from '../../actions';
import { selectBusiness, selectUser, selectStores } from '../../selectors';
import logger from '../../services/logger';
import { getToasterOptions, takeAsyncAction } from '../helpers';
import { FACEBOOK_PAGE_SELECT, CONTINUE_FACEBOOK_ANTI_SCUM } from '../../constants';
import createIntegrationFacebook from '../createIntegrationFacebook/createIntegrationFacebook';
import getSmartFeed from '../getSmartFeed/getSmartFeed';
import { isFacebookConnected } from '../../services/facebook';
import getStore from '../getStore/getStore';
import getStoreFacebookPermissions from '../getStoreFacebookPermissions/getStoreFacebookPermissions';
import getFacebookStoresPaymentMethods from '../getFacebookStoresPaymentMethods/getFacebookStoresPaymentMethods';
import api from '../../services/api';
import getCustomAudiences from '../getCustomAudiences/getCustomAudiences';

export default function* connectStoreToFacebook({ payload }) {
  const {
    store,
    onCompleted,
  } = payload;
  const user = yield select(selectUser);
  const facebookIntegration = getUserFacebookIntegration(user);
  const business = yield select(selectBusiness);
  const stores = yield select(selectStores);
  const hasConnectedStores = stores.some((s) => isFacebookConnected(s));

  try {
    // anti-scum if first store to connect
    if (!hasConnectedStores) {
      yield put(showFacebookAntiScumModal(true));
      const { payload: confirmed } = yield call(takeAsyncAction, CONTINUE_FACEBOOK_ANTI_SCUM);
      yield put(showFacebookAntiScumModal(false));
      if (!confirmed) {
        yield put(setLoadingAsync(false));
        return;
      }
    }

    yield put(showBackdrop(true));
    yield put(setLoadingAsync(true));

    let success = false;
    let retry = false;

    while (!success) {
      yield put(showFacebookPageSelectModal({ show: true, retry, store }));
      yield put(showBackdrop(false));

      const { payload: selectedFacebookPage } = yield call(takeAsyncAction, FACEBOOK_PAGE_SELECT);
      const facebookLocation = _.get(selectedFacebookPage, 'locations.0');

      if (!selectedFacebookPage) {
        yield put(showFacebookPageSelectModal({ show: false }));
        yield put(showBackdrop(false));
        yield put(setLoadingAsync(false));
        return;
      }

      yield put(showBackdrop(true));

      yield fork(api.updateStoreHubspotRecord, _.get(store, 'id'), { initialFacebookAttempt: true });

      const createdFacebookIntegration = yield call(
        createIntegrationFacebook,
        business,
        store,
        facebookIntegration,
        {
          ...(facebookLocation && { location: facebookLocation }),
          name: selectedFacebookPage.name,
          pageId: selectedFacebookPage.pageId,
        },
        selectedFacebookPage,
      );

      const updatedStore = yield call(getStore, store.id);
      yield call(getFacebookStoresPaymentMethods);
      yield fork(getStoreFacebookPermissions, updatedStore);
      yield fork(getSmartFeed);

      // error in saga
      if (createdFacebookIntegration === false) {
        yield fork(api.updateStoreHubspotRecord, _.get(store, 'id'), { initialFacebookFail: true });
        yield put(showToaster(getToasterOptions('generic_request_error', 'error')));
        retry = true;
      } else {
        success = true;
        yield put(showFacebookPageSelectModal({ show: false }));
        yield put(showBackdrop(false));
        yield put(setLoadingAsync(false));
        // cancel
        if (createdFacebookIntegration === null) {
          return;
        }

        yield call(getCustomAudiences, business.id, true);

        yield fork(api.updateStoreHubspotRecord, _.get(store, 'id'), { initialFacebookSuccess: true });
        yield put(showToaster(getToasterOptions('connect_store_to_facebook_success', 'success', null, 5000)));
        if (onCompleted) {
          onCompleted();
        }
      }
    }
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'connectStoreToFacebook' },
      params: { user, business, store },
    });
    yield put(showToaster(getToasterOptions('generic_request_error', 'error')));
  }
}
