import _ from 'lodash';
import {
  call, put, take,
} from 'redux-saga/effects';
import api from '../../services/api';
import {
  setLoadingAsync,
  showBackdrop,
  showAdAccountSelectModal,
  setConfirmationDialog,
  showToaster,
} from '../../actions';
import logger from '../../services/logger';
import { getToasterOptions } from '../helpers';
import { AD_ACCOUNT_SELECT, RESULT_CONFIRMATION_DIALOG } from '../../constants';
import removeStoreIntegration from '../removeStoreIntegration/removeStoreIntegration';

export default function* createIntegrationFacebook(
  business,
  store,
  facebookIntegration,
  integration,
  selectedBusiness,
) {
  let businessManagerId;

  const businessId = _.get(business, 'id');
  const storeId = _.get(store, 'id');
  const businessIntegration = _.find(_.get(business, 'integrations'), { type: 'facebook' });
  const storeIntegration = _.find(_.get(store, 'integrations'), { type: 'facebook' });
  const accessToken = _.get(facebookIntegration, 'accessToken');
  const userId = _.get(facebookIntegration, 'userId');
  const pageId = _.get(integration, 'pageId');

  try {
    if (_.isEmpty(_.get(business, 'integrations'))) {
      yield call(api.createBusinessIntegrationFacebook, businessId, {});
    }

    if (_.isEmpty(_.get(store, 'integrations'))) {
      const facebookLocation = _.get(selectedBusiness, 'locations.0');
      const storeData = {
        type: 'facebook',
        pageId,
        avatar: selectedBusiness?.avatar,
        ...(facebookLocation && { location: facebookLocation }),
      };
      yield call(api.createStoreIntegrationFacebook, storeId, storeData);
    }

    if (_.isNil(_.get(storeIntegration, 'businessId'))) {
      const assignedBusinessManager = _.get(selectedBusiness, ['id']);
      const targetableCreatedBusinessManager = _.get(businessIntegration, ['businessManagerId']);

      const existingBusinessManagerId = assignedBusinessManager || targetableCreatedBusinessManager;
      if (existingBusinessManagerId) {
        const updateParams = {
          businessId: existingBusinessManagerId,
        };

        if (existingBusinessManagerId === targetableCreatedBusinessManager) {
          updateParams.accessToken = accessToken;
          updateParams.pageId = pageId;
        }

        yield call(
          api.updateBusinessManager,
          storeId,
          existingBusinessManagerId,
          updateParams,
        );
        businessManagerId = existingBusinessManagerId;
      } else {
        businessManagerId = yield call(
          api.createBusinessManager, storeId, { ...integration, accessToken, userId },
        );

        yield call(
          api.updateTargetableCreatedBusinessManager,
          businessId,
          businessManagerId,
          { businessManagerId },
        );
      }
    }

    if (_.isNil(_.get(storeIntegration, 'adAccountId'))) {
      businessManagerId = businessManagerId || _.get(storeIntegration, 'businessId');
      const adAccounts = yield call(api.getAdAccounts, storeId, businessManagerId, accessToken);
      if (_.isEmpty(adAccounts)) {
        const adAccountId = yield call(
          api.createAdAccount,
          storeId,
          businessManagerId || _.get(storeIntegration, 'businessId'),
          {
            name: integration.name,
            accessToken,
            pageId: integration.pageId,
          },
        );

        yield call(
          api.createAdAccountUser,
          storeId,
          businessManagerId,
          adAccountId,
          { accessToken, userId },
        );

        yield put(setConfirmationDialog({
          isOpen: true,
          cancelText: 'cancel',
          cancelDataCy: 'ConfirmationDialog_cancel_button',
          moreInfoText: 'next',
          moreInfoDataCy: 'ConfirmationDialog_next_button',
          text: 'You pay for your ads, we handle all the rest.',
          title: 'Let\'s jump into Facebook to pay for your ads.',
        }));

        let success = false;
        while (!success) {
          yield put(showBackdrop(false));
          yield put(setLoadingAsync(false));

          const dialogAction = yield take(RESULT_CONFIRMATION_DIALOG);
          const dialogPayload = _.get(dialogAction, 'payload');

          if (!dialogPayload) {
            yield call(removeStoreIntegration, storeId, 'facebook');
            yield put(setConfirmationDialog({ isOpen: false }));
            return null;
          }

          // eslint-disable-next-line no-loop-func
          yield new Promise((resolve) => {
            FB.ui({ // eslint-disable-line no-undef
              account_id: adAccountId,
              display: 'popup',
              method: 'ads_payment',
            }, resolve);
          });

          yield put(setLoadingAsync(true));
          yield put(showBackdrop(true));

          const paymentMethods = yield api.getPaymentMethodsFacebook(
            businessManagerId, adAccountId, accessToken,
          );

          if (paymentMethods.active) {
            success = true;
            yield put(setConfirmationDialog({ isOpen: false }));
            yield put(setLoadingAsync(false));
            yield put(showBackdrop(false));
          } else {
            yield put(showToaster(getToasterOptions('error_whoops', 'error')));
          }
        }
      } else {
        let success = false;
        while (!success) {
          yield put(showBackdrop(false));
          yield put(setLoadingAsync(false));
          yield put(showAdAccountSelectModal({
            show: true,
            adAccounts,
            businessManagerId,
            store,
          }));
          const action = yield take(AD_ACCOUNT_SELECT);
          const adAccountId = _.get(action, ['payload', 'id']);
          if (!adAccountId) {
            yield call(removeStoreIntegration, storeId, 'facebook');
            yield put(showAdAccountSelectModal({ show: false }));
            return null;
          }
          yield put(showBackdrop(true));
          yield put(setLoadingAsync(true));
          try {
            yield call(api.updateAdAccount, accessToken, adAccountId, businessManagerId, storeId);
            success = true;
          } catch (e) {
            logger.error({
              error: e,
              context: { saga: 'createIntegrationFacebook - api.updateAdAccount' },
              params: {
                userId, adAccountId, businessManagerId, storeId,
              },
            });
            yield put(showToaster(getToasterOptions('error_whoops', 'error')));
            success = false;
          }
        }

        yield put(showAdAccountSelectModal({ show: false }));
      }
    }

    // add the facebook default image to cloudinary
    try {
      yield call(api.getFacebookDefaultImage, businessId, storeId, accessToken);
    } catch (e) {
      logger.error({
        error: e,
        context: { saga: 'createIntegrationFacebook - api.getFacebookDefaultImage' },
        params: {
          userId, selectedBusiness, businessId, storeId,
        },
      });
    }

    return true;
  } catch (e) {
    yield call(removeStoreIntegration, storeId, 'facebook');
    yield put(showAdAccountSelectModal({ show: false }));
    logger.error({
      error: e,
      context: { saga: 'createIntegrationFacebook - api.updateAdAccount' },
      params: { userId, businessId, storeId },
    });
    return false;
  }
}
