import {
  call, put,
} from 'redux-saga/effects';
import _ from 'lodash';

import { ONBOARDING_META_ACTION } from '../../constants';
import { setLoadingAsync } from '../../actions';
import { takeAsyncAction } from '../helpers';
import history from '../../services/history';
import {
  getOnboardingMetaRegistrationRoute,
  getOnboardingMetaRegistrationActionRoute,
} from '../../routes';
import connectFacebookAccount from '../connectFacebookAccount/connectFacebookAccount';

// this intentionally has no try / catch.
// whatever calls this needs to handle what to do if error
export default function* handleMetaRegistration() {
  let act;

  do {
    if (!act) {
      history.push(getOnboardingMetaRegistrationRoute());
    }
    yield put(setLoadingAsync(false));
    const { payload: data } = yield call(takeAsyncAction, ONBOARDING_META_ACTION);

    act = _.get(data, 'action', null);

    if (act === 'connect') {
      const connected = yield call(connectFacebookAccount, { payload: { showToast: false } });
      if (connected) {
        act = 'successful';
      } else {
        act = 'error';
      }
    }

    if (act && act !== 'home' && act !== 'connect') {
      history.push(getOnboardingMetaRegistrationActionRoute(act));
    }

    if (_.isNil(act) || act !== 'home') {
      continue; // eslint-disable-line no-continue
    }
  } while (_.isNil(act) || act !== 'home');
}
