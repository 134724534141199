import _ from 'lodash';
import {
  put, select, call, fork,
} from 'redux-saga/effects';
import { getUserFacebookIntegration } from '@targetable/targetable-web-framework/lib/services/user';
import api from '../../services/api';
import {
  setLoadingAsync,
  showBackdrop,
  showToaster,
} from '../../actions';
import logger from '../../services/logger';
import { selectUser } from '../../selectors';
import { getToasterOptions } from '../helpers';
import getFacebookStoresPaymentMethods from '../getFacebookStoresPaymentMethods/getFacebookStoresPaymentMethods';
import getSmartFeed from '../getSmartFeed/getSmartFeed';

export default function* addPaymentMethodToAccount({ payload: { adAccountId, businessId } }) {
  try {
    const user = yield select(selectUser);

    const userFbIntegration = getUserFacebookIntegration(user);
    const accessToken = _.get(userFbIntegration, 'accessToken');
    let success = false;
    while (!success) {
      yield put(showBackdrop(false));
      yield put(setLoadingAsync(false));

      // eslint-disable-next-line no-loop-func
      yield new Promise((resolve) => {
        FB.ui({ // eslint-disable-line no-undef
          account_id: adAccountId,
          display: 'popup',
          method: 'ads_payment',
        }, resolve);
      });

      yield put(setLoadingAsync(true));
      yield put(showBackdrop(true));

      const paymentMethods = yield call(
        api.getPaymentMethodsFacebook,
        businessId,
        adAccountId,
        accessToken,
      );

      if (paymentMethods.active) {
        success = true;
        yield put(setLoadingAsync(false));
        yield put(showBackdrop(false));
      } else {
        yield put(showToaster(getToasterOptions('error_whoops', 'error')));
        yield put(setLoadingAsync(false));
        yield put(showBackdrop(false));
        return false;
      }
    }
    yield call(getFacebookStoresPaymentMethods);
    yield fork(getSmartFeed);
    return true;
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'addPaymentMethodToAdAccount' },
      params: { businessId, adAccountId },
    });
    return false;
  }
}
