import _ from 'lodash';
import { call, select } from 'redux-saga/effects';

import api from '../../services/api';
import auth from '../../services/auth';
import history from '../../services/history';
import {
  getLoadingRoute,
  getRejectedRoute,
  getVerifyEmailRoute,
} from '../../routes';
import logger from '../../services/logger';

import getActiveUserAndAllUsers from '../getActiveUserAndAllUsers/getActiveUserAndAllUsers';
import handleReturningUser from '../handleReturningUser/handleReturningUser';
import handleNewUser from '../handleNewUser/handleNewUser';
import handleInvitedUser from '../handleInvitedUser/handleInvitedUser';
import { selectUser } from '../../selectors';
import getUser from '../getUser/getUser';
import initSocket from '../initSocket/initSocket';

export default function* initUser({ payload }) {
  try {
    // this is the user that comes directly from Auth0
    // this is not a targetable user and has a different schema
    // {
    //    at_hash: "Pb8Ezq24LbaJT8zKMXZZgw"
    //    aud: "9DBCRqK6dU7DOCl3efphPw45nc78j811"
    //    email: "chris@targetable.com"
    //    email_verified: true
    //    exp: 1594061780
    //    family_name: "Katz"
    //    given_name: "Chris"
    //    https://targetable.io/connection: "google-oauth2"
    //    https://targetable.io/couponCode: "34r2va"
    //    https://targetable.io/email: "chris@targetable.com"
    //    https://targetable.io/inviteCode: "234fqkjnf"
    //    https://targetable.io/inviteUserId: "invited_users_id"
    //    iat: 1594061480
    //    iss: "https://login.dev.targetable.io/"
    //    locale: "en"
    //    name: "Chris Katz"
    //    nickname: "chris"
    //    nonce: "KvqcPw4CGOZYqeG1GTyDhkcbVlMz5fSf"
    //    picture: "https://lh6.googleusercontent.com/-Yy-tRPfAIco/AAAAAAAAAAI/AAAAAAAAAAA/AMZuuckPFJAsSRhrKR7ftdxbuCWlGtCj4Q/photo.jpg"
    //    sub: "google-oauth2|110477611303180933537"
    //    updated_at: "2020-07-06T18:27:51.892Z"
    // }
    const auth0User = auth.getUser();

    const auth0Id = _.get(auth0User, 'sub');
    const targetableConnection = _.get(auth0User, 'https://targetable.io/connection') === 'targetable';
    const emailVerified = _.get(auth0User, 'email_verified');

    // uncomment to enable coupon codes
    // let couponCode = _.get(payload, 'couponCode');
    let email = _.get(payload, 'email');
    let inviteCode = _.get(payload, 'inviteCode');
    let inviteUserId = _.get(payload, 'inviteUserId');
    const originalTargetRoute = _.get(payload, 'originalTarget');
    const businessId = _.get(payload, 'businessId');
    const isRoot = _.get(payload, 'isRoot');

    // this only applies to username / password login
    // as the social providers already does this for us
    if (emailVerified === false && targetableConnection) {
      const metadata = {};

      // uncomment to enable coupon codes
      // if (_.isNil(couponCode) === false) {
      //   metadata.couponCode = couponCode;
      // }

      if (_.isNil(inviteCode) === false) {
        metadata.inviteCode = inviteCode;
        metadata.inviteUserId = inviteUserId;
      }

      if (_.isNil(email) === false) {
        metadata.email = email;
      }

      if (_.isEmpty(metadata) === false) {
        yield call(api.updateAuth0User, auth0Id, { metadata });
      }

      history.push(getVerifyEmailRoute());
      return;
    }

    // this is intentionally after the verification logic
    // to not show different loading icons
    history.push(getLoadingRoute());

    // get the user if the user already exists using the auth0Id
    // if the user doesn't exists an object with the auth0Id is returned from BE
    const user = yield call(getActiveUserAndAllUsers);

    // if an object is not returned, skip the handling logic
    // getUserByAuth0Id already handles itself having an error
    if (_.isNil(user)) {
      return;
    }

    // uncomment to enable coupon codes
    // const couponCodeFromMetadata = _.get(auth0User, 'https://targetable.io/couponCode');
    const emailFromMetadata = _.get(auth0User, 'https://targetable.io/email');
    const inviteCodeFromMetadata = _.get(auth0User, 'https://targetable.io/inviteCode');
    const inviteUserIdFromMetadata = _.get(auth0User, 'https://targetable.io/inviteUserId');

    // uncomment to enable coupon codes
    // couponCode = couponCodeFromMetadata || couponCode;
    email = emailFromMetadata || email;
    inviteCode = inviteCodeFromMetadata || inviteCode;
    inviteUserId = inviteUserIdFromMetadata || inviteUserId;

    const isInvitedUser = inviteCode || inviteUserId;
    const isReturningUser = _.get(user, 'onboarded');
    const isNewUser = !isInvitedUser && !isReturningUser;

    if (isNewUser) {
      // uncomment to enable coupon codes
      // yield call(handleNewUser, user, email, couponCode);
      yield call(handleNewUser, user, email);
    } else if (isInvitedUser && !isReturningUser) {
      yield call(handleInvitedUser, auth0Id, inviteCode, inviteUserId);
    } else {
      yield call(handleReturningUser, user, originalTargetRoute, businessId, isRoot);
    }

    yield call(initSocket);

    // clean up auth0 metadata
    if (targetableConnection && (isNewUser || isInvitedUser)) {
      const activeUser = yield select(selectUser);
      const userWithAuthMeta = yield call(getUser, activeUser.id, true);
      const userMetadata = _.get(userWithAuthMeta, 'user_metadata');
      if (_.get(userMetadata, 'couponCode')
        || _.get(userMetadata, 'email')
        || _.get(userMetadata, 'inviteCode')
        || _.get(userMetadata, 'inviteUserId')
      ) {
        delete userMetadata.couponCode;
        delete userMetadata.email;
        delete userMetadata.inviteCode;
        delete userMetadata.inviteUserId;
        yield call(api.updateAuth0User, auth0Id, { metadata: userMetadata });
      }
    }
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'initUser' },
    });
    history.push(getRejectedRoute());
  }
}
