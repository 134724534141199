import { all, takeLatest } from 'redux-saga/effects';

import * as constants from '../../constants';

import addPaymentMethodToAdAccount from '../addPaymentMethodToAdAccount/addPaymentMethodToAdAccount';
import cancelStripeSubscription from '../cancelStripeSubscription/cancelStripeSubscription';
import deleteStripeSubscriptionItem from '../deleteStripeSubscriptionItem/deleteStripeSubscriptionItem';
import changeBusiness from '../changeBusiness/changeBusiness';
import closeCampaignWizard from '../closeCampaignWizard/closeCampaignWizard';
import connectFacebookAccount from '../connectFacebookAccount/connectFacebookAccount';
import connectInstagramAccount from '../connectInstagramAccount/connectInstagramAccount';
import connectInstagramAccountToAdAccount from '../connectInstagramAccountToAdAccount/connectInstagramAccountToAdAccount';
import connectStoreToFacebook from '../connectStoreToFacebook/connectStoreToFacebook';
import deleteCampaign from '../deleteCampaign/deleteCampaign';
import deleteUserAccount from '../deleteUserAccount/deleteUserAccount';
import duplicateCampaign from '../duplicateCampaign/duplicateCampaign';
import pauseOrUnpauseCampaign from '../pauseOrUnpauseCampaign/pauseOrUnpauseCampaign';
import ensureFacebookConnection from '../ensureFacebookConnection/ensureFacebookConnection';
import ensureSubscription from '../ensureSubscription/ensureSubscription';
import getBusinessUsers from '../getBusinessUsers/getBusinessUsers';
import getCampaignsByStoreAndStatus from '../getCampaignsByStoreAndStatus/getCampaignsByStoreAndStatus';
import getFacebookCampaignLanguages from '../getFacebookCampaignLanguages/getFacebookCampaignLanguages';
import getFacebookPages from '../getFacebookPages/getFacebookPages';
import getImages from '../getImages/getImages';
import getMoreImages from '../getMoreImages/getMoreImages';
import getInsightsByBusiness from '../getInsightsByBusiness/getInsightsByBusiness';
import getSmartFeed from '../getSmartFeed/getSmartFeed';
import handleAddBusiness from '../handleAddBusiness/handleAddBusiness';
import handleAddStore from '../handleAddStore/handleAddStore';
import handleAddStripePaymentMethod from '../handleAddStripePaymentMethod/handleAddStripePaymentMethod';
import handleEditBusinessName from '../handleEditBusinessName/handleEditBusinessName';
import handleEditStoreName from '../handleEditStoreName/handleEditStoreName';
import handleFeedbackDialog from '../handleFeedbackDialog/handleFeedbackDialog';
import handleReactivateUser from '../handleReactivateUser/handleReactivateUser';
import handleUpdateStripePaymentMethod from '../handleUpdateStripePaymentMethod/handleUpdateStripePaymentMethod';
import initUser from '../initUser/initUser';
import inviteUser from '../inviteUser/inviteUser';
import loadingAsync from '../loadingAsync/loadingAsync';
import openCampaignWizard from '../openCampaignWizard/openCampaignWizard';
import openSuggestedCampaignDetail from '../openSuggestedCampaignDetail/openSuggestedCampaignDetail';
import saveSuggestedCampaign from '../saveSuggestedCampaign/saveSuggestedCampaign';
import reactivateStripeSubscription from '../reactivateStripeSubscription/reactivateStripeSubscription';
import reinviteUser from '../reinviteUser/reinviteUser';
import removeUser from '../removeUser/removeUser';
import saveCampaignForm from '../saveCampaignForm/saveCampaignForm';
import searchImages from '../searchImages/searchImages';
import showConfirmationDialog from '../showConfirmationDialog/showConfirmationDialog';
import submitCampaignForm from '../submitCampaignForm/submitCampaignForm';
import submitFeedback from '../submitFeedback/submitFeedback';
import updateBusiness from '../updateBusiness/updateBusiness';
import getStoresFacebookInfo from '../getStoresFacebookInfo/getStoresFacebookInfo';
import getBusinessSuggestedCampaigns from '../getBusinessSuggestedCampaigns/getBusinessSuggestedCampaigns';
import getInvoiceHistory from '../getInvoiceHistory/getInvoiceHistory';
import createActivityLog from '../createActivityLog/createActivityLog';
import removeImages from '../removeImages/removeImages';
import updateImageTags from '../updateImageTags/updateImageTags';
import unpauseStripeSubscriptionCollection from '../unpauseStripeSubscriptionCollection/unpauseStripeSubscriptionCollection';
import publishMarketingAd from '../publishMarketingAd/publishMarketingAd';
import marketingAdFeedbackDialog from '../marketingAdFeedbackDialog/marketingAdFeedbackDialog';
import submitAdFeedback from '../submitAdFeedback/submitAdFeedback';
import sendMailChimpTestEmail from '../sendMailChimpTestEmail/sendMailChimpTestEmail';
import updateActivityLog from '../updateActivityLog/updateActivityLog';
import getSilectisFacebookMetrics from '../getSilectisFacebookMetrics/getSilectisFacebookMetrics';
import getSilectisGoogleMetrics from '../getSilectisGoogleMetrics/getSilectisGoogleMetrics';
import getSilectisMailchimpMetrics from '../getSilectisMailchimpMetrics/getSilectisMailchimpMetrics';
import updateStripeSubscription from '../updateStripeSubscription/updateStripeSubscription';
import getMarketingChannels from '../getMarketingChannels/getMarketingChannels';
import enableDisableStore from '../enableDisableStore/enableDisableStore';
import authorizeMailchimp from '../authorizeMailchimp/authorizeMailchimp';
import getGoogleAssets from '../getGoogleAssets/getGoogleAssets';
import retryCampaign from '../retryCampaign/retryCampaign';
import pauseMailchimpCampaign from '../pauseMailchimpCampaign/pauseMailchimpCampaign';
import showPublishMarketingDialog from '../showPublishMarketingDialog/showPublishMarketingDialog';
import adRequest from '../adRequest/adRequest';
import updateMarketingSuggestedCampaign from '../updateMarketingSuggestedCampaign/updateMarketingSuggestedCampaign';
import authorizeGoogle from '../authorizeGoogle/authorizeGoogle';
import translateAddressToCoordinates from '../translateAddressToCoordinates/translateAddressToCoordinates';
import getComments from '../getComments/getComments';
import postComment from '../postComment/postComment';
import getAllLeadGenForms from '../getAllLeadGenForms/getAllLeadGenForms';

export default function* rootSaga() {
  yield all([
    takeLatest(constants.ADD_STRIPE_PAYMENT_METHOD,
      loadingAsync,
      handleAddStripePaymentMethod),
    takeLatest(constants.CANCEL_STRIPE_SUBSCRIPTION, loadingAsync, cancelStripeSubscription),
    takeLatest(
      constants.DELETE_STRIPE_SUBSCRIPTION_ITEM, loadingAsync, deleteStripeSubscriptionItem,
    ),
    takeLatest(constants.CHANGE_BUSINESS, loadingAsync, changeBusiness),
    takeLatest(constants.CLOSE_CAMPAIGN_WIZARD, closeCampaignWizard),
    takeLatest(constants.CONNECT_FACEBOOK_ACCOUNT, loadingAsync, connectFacebookAccount),
    takeLatest(
      constants.CONNECT_INSTAGRAM_ACCOUNT,
      ensureFacebookConnection,
      loadingAsync,
      connectInstagramAccount,
    ),
    takeLatest(
      constants.CONNECT_INSTAGRAM_ACCOUNT_TO_AD_ACCOUNT,
      ensureFacebookConnection,
      loadingAsync,
      connectInstagramAccountToAdAccount,
    ),
    takeLatest(
      constants.CONNECT_STORE_TO_FACEBOOK,
      ensureFacebookConnection,
      connectStoreToFacebook,
    ),
    takeLatest(constants.DELETE_CAMPAIGN, deleteCampaign),
    takeLatest(constants.DELETE_USER_ACCOUNT, deleteUserAccount),
    takeLatest(constants.DUPLICATE_CAMPAIGN, duplicateCampaign),
    takeLatest(constants.PAUSE_UNPAUSE_CAMPAIGN, pauseOrUnpauseCampaign),
    takeLatest(constants.EDIT_BUSINESS_NAME, loadingAsync, handleEditBusinessName),
    takeLatest(constants.EDIT_STORE_NAME, loadingAsync, handleEditStoreName),
    takeLatest(constants.ENABLE_DISABLE_STORE, enableDisableStore),
    takeLatest(constants.GET_BUSINESS_USERS, getBusinessUsers),
    takeLatest(constants.GET_CAMPAIGNS, getCampaignsByStoreAndStatus),
    takeLatest(constants.GET_FACEBOOK_CAMPAIGN_LANGUAGES, getFacebookCampaignLanguages),
    takeLatest(constants.GET_FACEBOOK_PAGES, loadingAsync, getFacebookPages),
    takeLatest(constants.GET_IMAGES, loadingAsync, getImages),
    takeLatest(constants.REMOVE_IMAGES, loadingAsync, removeImages),
    takeLatest(constants.UPDATE_TAG_IMAGES, loadingAsync, updateImageTags),
    takeLatest(constants.GET_MORE_IMAGES, getMoreImages),
    takeLatest(constants.GET_SMARTFEED, getSmartFeed),
    takeLatest(constants.GET_BUSINESS_INSIGHTS, getInsightsByBusiness),
    takeLatest(
      constants.HANDLE_FEEDBACK_DIALOG,
      loadingAsync,
      ensureSubscription,
      handleFeedbackDialog,
    ),
    takeLatest(constants.HANDLE_CANCELLED_SUBSCRIPTION, ensureSubscription),
    takeLatest(constants.MULTI_BUSINESS_ADD_BUSINESS, ensureSubscription, handleAddBusiness),
    takeLatest(constants.MULTI_STORE_ADD_STORE, ensureSubscription, handleAddStore),
    takeLatest(constants.INIT_USER, initUser),
    takeLatest(constants.INVITE_USER, loadingAsync, inviteUser),
    takeLatest(constants.OPEN_CAMPAIGN_WIZARD, openCampaignWizard),
    takeLatest(constants.OPEN_SUGGESTED_CAMPAIGN_DETAIL, openSuggestedCampaignDetail),
    takeLatest(constants.SAVE_SUGGESTED_CAMPAIGN, saveSuggestedCampaign),
    takeLatest(constants.REACTIVATE_USER, loadingAsync, handleReactivateUser),
    takeLatest(constants.REINVITE_USER, reinviteUser),
    takeLatest(constants.REMOVE_USER, removeUser),
    takeLatest(
      constants.REACTIVATE_STRIPE_SUBSCRIPTION,
      loadingAsync,
      reactivateStripeSubscription,
    ),
    takeLatest(
      constants.UNPAUSE_STRIPE_COLLECTION, loadingAsync, unpauseStripeSubscriptionCollection,
    ),
    takeLatest(constants.SAVE_CAMPAIGN_FORM, loadingAsync, saveCampaignForm),
    takeLatest(constants.SEARCH_IMAGES, loadingAsync, searchImages),
    takeLatest(constants.SHOW_CONFIRMATION_DIALOG, showConfirmationDialog),
    takeLatest(
      constants.SUBMIT_CAMPAIGN_FORM,
      ensureSubscription,
      ensureFacebookConnection,
      loadingAsync,
      submitCampaignForm,
    ),
    takeLatest(constants.SUBMIT_FEEDBACK, loadingAsync, submitFeedback),
    takeLatest(constants.UPDATE_BUSINESS, updateBusiness),
    takeLatest(
      constants.UPDATE_STRIPE_PAYMENT_METHOD,
      loadingAsync,
      handleUpdateStripePaymentMethod,
    ),
    takeLatest(
      constants.ADD_PAYMENT_METHOD_TO_ACCOUNT,
      ensureFacebookConnection,
      addPaymentMethodToAdAccount,
    ),
    takeLatest(constants.GET_STORES_FB_INFO, getStoresFacebookInfo),
    takeLatest(constants.GET_BUSINESS_SUGGESTED_CAMPAIGNS, getBusinessSuggestedCampaigns),
    takeLatest(constants.GET_INVOICE_HISTORY, getInvoiceHistory),
    takeLatest(constants.CREATE_ACTIVITY_LOG, createActivityLog),
    takeLatest(constants.PUBLISH_MARKETING_AD, publishMarketingAd),
    takeLatest(constants.MARKETING_AD_SHOW_FEEDBACK_DIALOG, marketingAdFeedbackDialog),
    takeLatest(constants.MARKETING_AD_SUBMIT_FEEDBACK, submitAdFeedback),
    takeLatest(constants.SEND_MAIL_CHIMP_TEST_EMAIL, sendMailChimpTestEmail),
    takeLatest(
      constants.UPDATE_MARKETING_SUGGESTED_CAMPAIGN,
      loadingAsync,
      updateMarketingSuggestedCampaign,
    ),
    takeLatest(constants.UPDATE_ACTIVITY_LOG, updateActivityLog),
    takeLatest(constants.GET_SILECTIS_FACEBOOK_METRICS, getSilectisFacebookMetrics),
    takeLatest(constants.GET_SILECTIS_GOOGLE_METRICS, getSilectisGoogleMetrics),
    takeLatest(constants.GET_SILECTIS_MAILCHIMP_METRICS, getSilectisMailchimpMetrics),
    takeLatest(constants.GET_GOOGLE_ASSETS, getGoogleAssets),
    takeLatest(
      constants.UPDATE_STRIPE_COLLECTION, loadingAsync, updateStripeSubscription,
    ),
    takeLatest(constants.GET_MARKETING_CHANNELS, getMarketingChannels),
    takeLatest(constants.AUTHORIZE_MAILCHIMP, authorizeMailchimp),
    takeLatest(constants.AUTHORIZE_GOOGLE, authorizeGoogle),
    takeLatest(constants.RETRY_CAMPAIGN, retryCampaign),
    takeLatest(constants.PAUSE_MAILCHIMP_CAMPAIGN, pauseMailchimpCampaign),
    takeLatest(constants.SHOW_PUBLISH_MARKETING_DIALOG, showPublishMarketingDialog),
    takeLatest(constants.AD_REQUEST, adRequest),
    takeLatest(constants.DATA_COORDINATES, translateAddressToCoordinates),
    takeLatest(constants.DATA_COMMENTS, getComments),
    takeLatest(constants.POST_COMMMENT, postComment),
    takeLatest(constants.GET_ALL_LEAD_GEN_FORMS, getAllLeadGenForms),
  ]);
}
