import _ from 'lodash';
import moment from 'moment';
import { take, put } from 'redux-saga/effects';
import { ActivityLogType } from '@targetable/targetable-types/dist/types/activity-log/types';
import { AdStatus } from '@targetable/targetable-types/dist/types/ad-service/types';
import { MarketingChannelTypes } from '@targetable/targetable-types/dist/types/marketing-channel/types';
import { FacebookSuggestedCampaignStatus } from '@targetable/targetable-types/dist/types/facebook-campaign/campaigns';
import { setLoadingAsync } from '../actions';
import dateService from '../services/date';

export function getToasterOptions(message, type = 'error', link = undefined, duration = null, open = true, externalLink = undefined, messageParams = undefined) {
  return {
    duration,
    message,
    open,
    type,
    link,
    externalLink,
    messageParams,
  };
}

export function* takeAsyncAction(pattern) {
  yield put(setLoadingAsync(false));
  const payload = yield take(pattern);
  yield put(setLoadingAsync(true));
  return payload;
}

export function validateBusinessOnboarded(business) {
  const businessIntegration = _.find(_.get(business, 'integrations'), { type: 'facebook' });
  return _.isNil(businessIntegration) === false && _.isEmpty(businessIntegration) === false;
}

export function validateStoreOnboarded(store) {
  return _.isNil(_.get(store, 'locations')) === false
      && _.isNil(_.get(store, 'name')) === false;
}

export function validateUserOnboarded(user) {
  return _.isNil(_.get(user, 'businessId')) === false;
}

export function facebookLoginPromise() {
  return new Promise((resolve) => {
    // eslint-disable-next-line no-undef
    FB.getLoginStatus((statusResponse) => {
      if (statusResponse.status === 'connected') {
        resolve({
          accessToken: statusResponse.authResponse.accessToken,
          userId: statusResponse.authResponse.userID,
        });
      } else {
        // eslint-disable-next-line no-undef
        FB.login((response) => {
          if (response.authResponse) {
            resolve({
              accessToken: response.authResponse.accessToken,
              userId: response.authResponse.userID,
            });
          } else {
            resolve();
          }
        }, {
          scope: [
            'user_photos',
            'email',
            'pages_show_list',
            'ads_management',
            'business_management',
            'pages_read_engagement',
            'public_profile',
          ].join(','),
        });
      }
    });
  });
}

export function safeApiCall(apiMethod, defaultValue) {
  return async (...args) => {
    try {
      return await apiMethod(...args);
    } catch (e) {
      return defaultValue;
    }
  };
}

export function getNotExpiredInsighstOrSuggestedCampaigns(items) {
  const now = moment.utc();
  return items?.filter((item) => {
    let overrideAutoAging = false;
    let expiryDate = null;
    let published = null;
    if (_.get(item, 'type') && _.includes(['google', 'tiktok'], _.get(item, 'type'))) {
      overrideAutoAging = _.get(item, 'smartFeed.overrideAutoAging', false);
      expiryDate = _.get(item, 'smartFeed.expiryDate', null);
      published = moment(_.get(item, 'publishingLastAttemptAt')).valueOf();
    } else {
      overrideAutoAging = _.get(item, 'overrideAutoAging', false);
      expiryDate = _.get(item, 'expiryDate', null);
      published = _.get(item, 'published');
    }
    if (overrideAutoAging) return true;
    if (_.isNil(expiryDate)) return now.diff(published, 'days') < 60;
    return Date.parse(expiryDate) > now;
  });
}

export function getExpiredInsighstOrSuggestedCampaigns(items) {
  const now = moment.utc();
  return items?.filter((item) => {
    let expiryDate = null;
    let published = null;
    if (_.get(item, 'type') && _.includes(['google', 'tiktok'], _.get(item, 'type'))) {
      expiryDate = _.get(item, 'smartFeed.expiryDate', null);
      published = moment(_.get(item, 'publishingLastAttemptAt')).valueOf();
    } else {
      expiryDate = _.get(item, 'expiryDate', null);
      published = _.get(item, 'published');
    }
    if (_.isNil(expiryDate)) return now.diff(published, 'days') > 60;
    return Date.parse(expiryDate) < now;
  });
}

export const shouldDisplayActivityLog = (activityLog) => {
  if (activityLog?.type === ActivityLogType.CampaignStatus) {
    // only fb, google or mailchimp
    if (![
      MarketingChannelTypes.Facebook,
      MarketingChannelTypes.Google,
      MarketingChannelTypes.MailChimp,
    ].includes(activityLog?.meta?.type || activityLog?.meta?.entityType)) return false;
    // not dismissed or clicked
    if (activityLog?.dismissed || activityLog?.clicked) return false;

    return activityLog?.meta?.entity?.id // has entityId
    && ![ // remove draft, deleted or archived status TGT-5242
      AdStatus.Draft,
      AdStatus.Deleted,
      AdStatus.Archived,
      AdStatus.SmartFeed,
      AdStatus.DraftApproved,
      AdStatus.DraftRejected,
      // TGT-7312 - Removed empty records because of their unmapped statuses
      FacebookSuggestedCampaignStatus.Draft,
      FacebookSuggestedCampaignStatus.Review,
      FacebookSuggestedCampaignStatus.Published,
      FacebookSuggestedCampaignStatus.Approved,
      FacebookSuggestedCampaignStatus.Rejected,
      FacebookSuggestedCampaignStatus.Saved,
    ].includes(activityLog?.meta?.entity?.status);
  }
  return false;
};

export const parseActivityLog = (al) => {
  const isFacebook = al?.meta?.type === MarketingChannelTypes.Facebook;
  const isGoogleOrMailChimp = [MarketingChannelTypes.Google, MarketingChannelTypes.MailChimp]
    .includes(al?.meta?.entityType);

  const startDate = (isFacebook) ? dateService.getDateFromSchedule(_.get(al, 'meta.entity.ads.0.schedule'), 'startDate').valueOf() : new Date(al.startDate).getTime();

  let endDate;
  if (isFacebook) {
    if (_.get(al, 'meta.entity.ads.0.schedule.endDate') === undefined) {
      endDate = 'evergreen';
    } else {
      endDate = dateService.getDateFromSchedule(_.get(al, 'meta.entity.ads.0.schedule'), 'endDate').valueOf();
    }
  } else if (isGoogleOrMailChimp) {
    if (al.endDate === undefined) {
      endDate = 'evergreen';
    } else {
      endDate = new Date(al.endDate).getTime();
    }
  }
  return {
    id: al?.id,
    date: al?.created,
    businessId: al?.businessId,
    type: al?.type,
    entityType: al?.meta?.type || al?.meta?.entityType,
    status: al?.meta?.entity?.status,
    startDate,
    endDate,
    clicked: al?.clicked || 0,
    dismissed: al?.dismissed || false,
    name: al?.meta?.entity?.name,
    campaign: al?.meta?.entity,
  };
};

/**
 * Generates shortened breadcrumbs based on the input string and maximum length.
 * @param {string} inputString - The input breadcrumb string.
 * @param {number} [maxLength=80] - The maximum length for the shortened breadcrumb.
 * @returns {string} The generated shortened breadcrumb.
 */
export const generateShortenedBreadcrumbs = (inputString, maxLength = 80) => {
  const parts = inputString.split(' / ');
  const businessName = parts[0];
  const contentName = parts[parts.length - 1];

  if (businessName.length + 12 > maxLength) {
    // Truncate businessName to fit and add '/.../'
    return `${businessName.substring(0, maxLength - 15)} /.../ ${contentName}`;
  }

  if (businessName.length + contentName.length + 8 <= maxLength) {
    return inputString;
  }

  const partialContentNameLength = maxLength - businessName.length - 11;
  const partialContentName = contentName.substring(0, partialContentNameLength);

  return `${businessName} /.../ ${partialContentName}...`;
};

/**
 * Generates a breadcrumb title based on the provided campaign, business, and mobile status.
 *
 * @param {Object} campaign - The campaign object.
 * @param {Object} business - The business object.
 * @param {boolean} isMobile - Flag indicating if the view is on mobile.
 * @returns {string} The generated breadcrumb title.
 */
export const getBreadcrumbTitleByCampaign = (campaign, business, isMobile) => {
  // Determine the placementBreadcrumb
  let placementBreadcrumb = '';
  const adPlacementFB = _.get(campaign?.ads, '0.ad.adPlacement.facebook', false);
  const adPlacementIG = _.get(campaign?.ads, '0.ad.adPlacement.instagram', false);
  if (![
    MarketingChannelTypes.Google,
    MarketingChannelTypes.MailChimp,
  ].includes(_.get(campaign, 'type', MarketingChannelTypes.Facebook))) {
    placementBreadcrumb = adPlacementFB ? 'Facebook' : '';
    if (adPlacementIG) {
      placementBreadcrumb += placementBreadcrumb ? ' / ' : '';
      placementBreadcrumb += 'Instagram';
    }
  } else {
    placementBreadcrumb = campaign?.type === MarketingChannelTypes.MailChimp ? 'Managed Email Marketing' : campaign?.type;
  }

  // Determine the campaignStatus
  let campaignStatus = String(campaign?.status).replace(/_/g, ' ');
  if ([AdStatus.SmartFeed, 'published'].includes(campaign?.status)) {
    campaignStatus = 'Suggested Campaign';
  }
  if (campaign?.withIssues) {
    campaignStatus = 'With Issues';
  }

  // Generate the breadcrumb
  const businessName = String(business?.name);
  const campaignName = String(campaign?.name);
  const breadcrumb = `${businessName}${placementBreadcrumb ? ` / ${placementBreadcrumb}` : ''} / ${campaignStatus} / ${campaignName}`.toUpperCase();

  // Return the appropriate breadcrumb based on isMobile
  return isMobile ? generateShortenedBreadcrumbs(breadcrumb) : breadcrumb;
};
