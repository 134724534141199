import {
  call, put, select,
} from 'redux-saga/effects';
import _ from 'lodash';

import { ADD_STORE_SELECT_PLACE } from '../../constants';
import { setBusiness, setLoadingAsync } from '../../actions';
import { takeAsyncAction } from '../helpers';
import { selectUser } from '../../selectors';
import updateUser from '../updateUser/updateUser';
import upsertBusiness from '../upsertBusiness/upsertBusiness';
import upsertStore from '../upsertStore/upsertStore';
import api from '../../services/api';
import history from '../../services/history';
import { getOnboardingStoreRegistrationRoute, getLoadingRoute } from '../../routes';
import logger from '../../services/logger';

// this intentionally has no try / catch.
// whatever calls this needs to handle what to do if error
export default function* handleStoreRegistration(stripeCoupon) {
  let businessId = null;
  let storeId = null;

  do {
    history.push(getOnboardingStoreRegistrationRoute());
    yield put(setLoadingAsync(false));
    const { payload: formData } = yield call(takeAsyncAction, ADD_STORE_SELECT_PLACE);

    const storeName = _.get(formData, 'name');
    // const storeLocations = _.get(formData, 'locations');

    history.push(getLoadingRoute());

    let business = yield call(upsertBusiness, { id: businessId, name: storeName, stripeCoupon });

    businessId = _.get(business, 'id');

    if (_.isNil(businessId)) {
      continue; // eslint-disable-line no-continue
    }

    const user = yield select(selectUser);
    const updatedUser = yield call(updateUser,
      { id: user.id, businessId, businessName: storeName });

    if (_.isNil(updatedUser)) {
      continue; // eslint-disable-line no-continue
    }

    business = yield call(api.getBusiness, businessId);
    yield put(setBusiness(business));

    const store = yield call(upsertStore, {
      id: storeId,
      businessId,
      name: storeName,
      // locations: storeLocations,
    });
    storeId = _.get(store, 'id');

    // The user must be created before create the husbpot record
    if (_.has(business, 'hubSpotId') === false) {
      try {
        yield call(api.createBusinessHubspotRecord, businessId);
      } catch (e) {
        logger.error({
          error: `Failed to create hubspot record for business ${businessId}`,
          context: { saga: 'handleStoreRegistration', severity: 'debug' },
          params: {
            businessId, updatedUser,
          },
        });
      }
    }

    if (_.isNil(storeId) === false) {
      // Updated on 01/Feb/2023 - TGT-6223
      // Prevent blocking user onboarding process on Hubspot integration errors.
      const hubspotFlagsToUpdate = {
        storeOnboardingCompleted: true,
        storeLocationCompleted: false,
      };

      try {
        yield call(api.updateStoreHubspotRecord, storeId, hubspotFlagsToUpdate);
      } catch (e) {
        logger.error({
          error: `Failed to update store [${storeId}] hubspot record for business ${businessId}`,
          context: { saga: 'handleStoreRegistration', severity: 'debug' },
          params: {
            storeId, ...hubspotFlagsToUpdate, businessId,
          },
        });
      }
    }
  } while (_.isNil(businessId) || _.isNil(storeId));
}
