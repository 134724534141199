import _ from 'lodash';
import {
  call,
  put,
  select,
} from 'redux-saga/effects';

import {
  getSmartFeed,
  resetApp,
  setLoadingAsync,
  showAddBusinessModal,
  showAddBusinessConfirmModal,
  showBackdrop,
  showToaster, setBusiness,
} from '../../actions';
import { selectUser } from '../../selectors';
import api from '../../services/api';
import logger from '../../services/logger';
import upsertBusiness from '../upsertBusiness/upsertBusiness';
import upsertStore from '../upsertStore/upsertStore';
import createStripeCustomer from '../createStripeCustomer/createStripeCustomer';
import updateBusiness from '../updateBusiness/updateBusiness';
import createDefaultInsights from '../createDefaultInsights/createDefaultInsights';
import createDefaultSuggestedCampaigns from '../createDefaultSuggestedCampaigns/createDefaultSuggestedCampaigns';
import upsertUser from '../upsertUser/upsertUser';

import localStorage from '../../services/localStorage';
import auth from '../../services/auth';
import { getToasterOptions, takeAsyncAction } from '../helpers';
import { ADD_BUSINESS_CONFIRM, CONTINUE_ADD_BUSINESS } from '../../constants';
import getActiveUserAndAllUsers from '../getActiveUserAndAllUsers/getActiveUserAndAllUsers';
import handleReturningUser from '../handleReturningUser/handleReturningUser';

export default function* handleAddBusiness() {
  const auth0Id = auth.getUserId();

  let newUserId = null;
  let newStoreId = null;
  let newBusinessId = null;
  try {
    yield put(showAddBusinessConfirmModal(true));
    const { payload: shouldContinue } = yield call(takeAsyncAction, CONTINUE_ADD_BUSINESS);
    yield put(showAddBusinessConfirmModal(false));
    if (shouldContinue === false) {
      yield put(setLoadingAsync(false));
      return;
    }

    while (true) {
      yield put(showBackdrop(false));
      yield put(showAddBusinessModal(true));
      const { payload: formData } = yield call(takeAsyncAction, ADD_BUSINESS_CONFIRM);

      // they cancelled out of adding a business
      if (formData === false) {
        yield put(setLoadingAsync(false));
        yield put(showBackdrop(false));
        yield put(showAddBusinessModal(false));
        return;
      }

      yield put(showBackdrop(true));
      yield put(resetApp());

      const storeName = _.get(formData, 'name');

      let business = yield call(upsertBusiness, {
        id: newBusinessId,
        name: storeName,
      });
      newBusinessId = _.get(business, 'id');
      if (_.isNil(newBusinessId)) {
        continue; // eslint-disable-line no-continue
      }

      const currentUser = yield select(selectUser);
      const createdUser = yield call(upsertUser, {
        id: newUserId,
        auth0Id,
        businessId: newBusinessId,
        businessName: storeName,
        givenName: _.get(currentUser, 'givenName'),
        familyName: _.get(currentUser, 'familyName'),
        email: _.get(currentUser, 'email'),
        phoneNumber: _.get(currentUser, 'phoneNumber', ''),
        onboarded: true,
      });
      newUserId = _.get(createdUser, 'id');
      if (_.isNil(newUserId)) {
        continue; // eslint-disable-line no-continue
      }

      const store = yield call(upsertStore, {
        id: newStoreId,
        businessId: newBusinessId,
        name: storeName,
      });
      newStoreId = _.get(store, 'id');
      if (_.isNil(newStoreId)) {
        continue; // eslint-disable-line no-continue
      }

      // The user must be created before create the husbpot record
      if (_.has(business, 'hubSpotId') === false) {
        try {
          yield call(api.createBusinessHubspotRecord, newBusinessId);
        } catch (e) {
          logger.error({
            error: `Failed to create hubspot record for business ${newBusinessId}`,
            context: { saga: 'handleAddBusiness', severity: 'debug' },
            params: {
              newBusinessId, createdUser, newStoreId,
            },
          });
        }
      }

      business = yield call(api.getBusiness, newBusinessId);
      yield put(setBusiness(business));

      try {
        yield call(api.associateUserHubspotRecordToBusinessHubspotRecord, newUserId);
      } catch (e) {
        logger.error({
          error: `Failed to associate user [${newUserId}] hubspot record to business [${newBusinessId}] hubspot record`,
          context: { saga: 'handleAddBusiness', severity: 'debug' },
          params: {
            newUserId,
            newBusinessId,
          },
        });
      }

      if (_.isNil(_.get(business, 'stripeCustomerId'))) {
        const customerId = yield call(createStripeCustomer, newBusinessId, storeName, _.get(currentUser, 'email'));
        if (customerId) {
          yield call(updateBusiness, {
            payload: { id: newBusinessId, stripeCustomerId: customerId },
          });
        } else {
          throw new Error('Failed to create billing record');
        }
      }

      yield call(createDefaultInsights, { businessId: newBusinessId });
      yield call(createDefaultSuggestedCampaigns,
        { businessId: newBusinessId, storeId: newStoreId });

      try {
        yield call(api.updateBusinessHubspotRecord, newBusinessId,
          { businessOnboardingCompleted: true });
      } catch (e) {
        logger.error({
          error: `Failed to update business [${newBusinessId}] hubspot record`,
          context: { saga: 'handleAddBusiness', severity: 'debug' },
          params: {
            newBusinessId,
            businessOnboardingCompleted: true,
          },
        });
      }

      const hubspotFieldsToUpdate = {
        storeOnboardingCompleted: true,
        storeLocationCompleted: false,
        storeTargetableSubscription: true,
      };

      try {
        yield call(api.updateStoreHubspotRecord, newStoreId, hubspotFieldsToUpdate);
      } catch (e) {
        logger.error({
          error: `Failed to update store [${newStoreId}] hubspot record for business ${newBusinessId}`,
          context: { saga: 'handleAddBusiness', severity: 'debug' },
          params: {
            ...hubspotFieldsToUpdate,
            newStoreId,
            newBusinessId,
          },
        });
      }

      // ensure local storage is set BEFORE getting the new users
      localStorage.setItem(`${auth0Id}:last_viewed_user_id`, newUserId);

      // re-get all the correct users
      const user = yield call(getActiveUserAndAllUsers);

      // if an object is not returned, skip the handling logic
      // getActiveUserAndAllUsers already handles itself having an error
      if (_.isNil(user)) {
        throw new Error('Getting latest user(s) did not return the new user we created');
      }

      yield call(handleReturningUser, user);
      // this is called here because it currently gets updated
      // by the page, which happens earlier when the user state is
      // updated. Easier to just call it here again
      yield put(getSmartFeed());
      yield put(setLoadingAsync(false));
      yield put(showBackdrop(false));
      yield put(showAddBusinessModal(false));
      break;
    }
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'handleAddBusiness' },
      params: { newUserId, newStoreId, newBusinessId },
    });

    if (_.isNil(newBusinessId) === false) {
      yield call(api.deleteBusiness, newBusinessId);
    }
    if (_.isNil(newStoreId) === false) {
      yield call(api.deleteStore, newStoreId);
    }
    if (_.isNil(newUserId) === false) {
      yield call(api.deleteUser, newUserId);
    }
    yield put(showAddBusinessModal(false));
    yield put(showBackdrop(false));
    yield put(setLoadingAsync(false));
    yield put(showToaster(getToasterOptions('generic_request_error', 'error')));
  }
}
