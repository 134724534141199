import _ from 'lodash';
import {
  call, put, select, all,
} from 'redux-saga/effects';

import { getUserFacebookIntegration } from '@targetable/targetable-web-framework/lib/services/user';
import logger from '../../services/logger';

import {
  setStoresPaymentMethods,
} from '../../actions';
import { selectStores, selectUser } from '../../selectors';
import api from '../../services/api';
import { isFacebookConnected } from '../../services/facebook';

export default function* getFacebookStoresPaymentMethods() {
  try {
    const user = yield select(selectUser);
    const stores = yield select(selectStores);

    const userFbIntegration = getUserFacebookIntegration(user);
    const accessToken = _.get(userFbIntegration, 'accessToken');

    // The current user might not have FB integrations (TGT-4714), thus causing accessToken
    // to be undefined. An API request without this queryStringParameter will produce a 500 error
    if (accessToken) {
      const filteredStores = _.filter(stores, (store) => isFacebookConnected(store));

      const paymentMethods = yield all(_.map(filteredStores, (filteredStore) => {
        const integrations = _.get(filteredStore, 'integrations');
        const storeFbIntegration = _.find(integrations, ({ type: 'facebook' }));
        return call(
          api.getPaymentMethodsFacebook,
          storeFbIntegration.businessId,
          storeFbIntegration.adAccountId,
          accessToken,
        );
      }));

      const storesPaymentMethods = _.map(paymentMethods, (paymentMethod, key) => {
        const storeId = _.get(filteredStores[key], 'id');
        const name = _.get(filteredStores[key], 'name');
        const integrations = _.get(filteredStores[key], 'integrations');
        const storeFbIntegration = _.find(integrations, ({ type: 'facebook' }));
        return {
          ...paymentMethod,
          storeId,
          name,
          adAccountId: storeFbIntegration.adAccountId,
          businessId: storeFbIntegration.businessId,
        };
      });

      yield put(setStoresPaymentMethods(storesPaymentMethods));
    }
  } catch (e) {
    logger.error({
      error: e,
      context: { saga: 'getFacebookStoresPaymentMethods' },
    });
  }
}
