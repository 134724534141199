export const CHANGE_BUSINESS = 'CHANGE_BUSINESS';
export const CLEAR_CAMPAIGN_WIZARD = 'CLEAR_CAMPAIGN_WIZARD';
export const CLOSE_CAMPAIGN_WIZARD = 'CLOSE_CAMPAIGN_WIZARD';
export const CLOSE_TOASTER = 'CLOSE_TOASTER';
export const CONNECT_INSTAGRAM_ACCOUNT = 'CONNECT_INSTAGRAM_ACCOUNT';
export const CONNECT_INSTAGRAM_ACCOUNT_TO_AD_ACCOUNT = 'CONNECT_INSTAGRAM_ACCOUNT_TO_AD_ACCOUNT';
export const CONNECT_FACEBOOK_ACCOUNT = 'CONNECT_FACEBOOK_ACCOUNT';
export const CONNECT_STORE_TO_FACEBOOK = 'CONNECT_STORE_TO_FACEBOOK';

export const DATA_BACKDROP = 'DATA_BACKDROP';
export const DATA_BUSINESS = 'DATA_BUSINESS';
export const DATA_BUSINESS_INSIGHTS = 'DATA_BUSINESS_INSIGHTS';
export const DATA_BUSINESS_INSIGHTS_LOADING = 'DATA_BUSINESS_INSIGHTS_LOADING';
export const DATA_CAMPAIGN = 'DATA_CAMPAIGN';
export const DATA_CAMPAIGN_ADS = 'DATA_CAMPAIGN_ADS';
export const DATA_CAMPAIGN_NAME = 'DATA_CAMPAIGN_NAME';
export const DATA_CAMPAIGN_GOAL = 'DATA_CAMPAIGN_GOAL';
export const DATA_CAMPAIGN_OBJECTIVE = 'DATA_CAMPAIGN_OBJECTIVE';
export const DATA_CAMPAIGN_UPDATE_ADS = 'DATA_CAMPAIGN_UPDATE_ADS';
export const DATA_CAMPAIGN_METRICS = 'DATA_CAMPAIGN_METRICS';
export const DATA_CAMPAIGN_TRENDS = 'DATA_CAMPAIGN_TRENDS';
export const DATA_CAMPAIGN_METRICS_LOADING = 'DATA_CAMPAIGN_METRICS_LOADING';
export const DATA_CAMPAIGN_METRICS_LOADING_ERROR = 'DATA_CAMPAIGN_METRICS_LOADING_ERROR';
export const DATA_BUSINESS_USERS = 'DATA_BUSINESS_USERS';
export const DATA_BUSINESS_USERS_LOADING = 'DATA_BUSINESS_USERS_LOADING';
export const DATA_BUSINESS_LEAD_FORMS = 'DATA_BUSINESS_LEAD_FORMS';
export const GET_BUSINESS_USERS = 'GET_BUSINESS_USERS';
export const GET_STORES_FB_INFO = 'GET_STORES_FB_INFO';

export const CLEAR_SUGGESTED_CAMPAIGN = 'CLEAR_SUGGESTED_CAMPAIGN';
export const DATA_SUGGESTED_CAMPAIGN = 'DATA_SUGGESTED_CAMPAIGN';

export const DATA_CAMPAIGNS = 'DATA_CAMPAIGNS';
export const DATA_CAMPAIGNS_CURSOR = 'DATA_CAMPAIGNS_CURSOR';
export const DATA_FACEBOOK_CAMPAIGN_LANGUAGES = 'DATA_FACEBOOK_CAMPAIGN_LANGUAGES';
export const DATA_FACEBOOK_CAMPAIGN_TARGETS = 'DATA_FACEBOOK_CAMPAIGN_TARGETS';
export const DATA_FACEBOOK_CONNECTED_BUSINESSES = 'DATA_FACEBOOK_CONNECTED_BUSINESSES';
export const DATA_FACEBOOK_PERMISSIONS_BY_STORES = 'DATA_FACEBOOK_PERMISSIONS_BY_STORES';
export const DATA_FACEBOOK_TOKEN_EXPIRED = 'DATA_FACEBOOK_TOKEN_EXPIRED';
export const DATA_INITIAL_IMAGES = 'DATA_INITIAL_IMAGES';
export const DATA_INITIAL_IMAGES_CURSOR = 'DATA_INITIAL_IMAGES_CURSOR';
export const DATA_IMAGES_MAM = 'DATA_IMAGES_MAM';
export const DATA_INSTAGRAM_ACCOUNTS = 'DATA_INSTAGRAM_ACCOUNTS';
export const DATA_IS_SEARCHING_MAM = 'DATA_IS_SEARCHING_MAM';
export const DATA_ONBOARDING_BUSINESS = 'DATA_ONBOARDING_BUSINESS';
export const DATA_STORES = 'DATA_STORES';
export const DATA_STORES_LOADING = 'DATA_STORES_LOADING';
export const DATA_STORES_FB_INFO_LOADING = 'DATA_STORES_FB_INFO_LOADING';
export const DATA_SET_USER_INFO = 'DATA_SET_USER_INFO';
export const DATA_USER_ACTIVE = 'DATA_USER_ACTIVE';
export const DATA_USER_ALL_USERS = 'DATA_USER_ALL_USERS';

export const DELETE_CAMPAIGN = 'DELETE_CAMPAIGN';
export const DELETE_USER_ACCOUNT = 'DELETE_USER_ACCOUNT';
export const DUPLICATE_CAMPAIGN = 'DUPLICATE_CAMPAIGN';
export const PAUSE_UNPAUSE_CAMPAIGN = 'PAUSE_UNPAUSE_CAMPAIGN';
export const RETRY_CAMPAIGN = 'RETRY_CAMPAIGN';
export const GET_BUSINESS_INSIGHTS = 'GET_BUSINESS_INSIGHTS';
export const GET_CAMPAIGNS = 'GET_CAMPAIGNS';
export const GET_IMAGES = 'GET_IMAGES';
export const REMOVE_IMAGES = 'REMOVE_IMAGES';
export const UPDATE_TAG_IMAGES = 'UPDATE_TAG_IMAGES';
export const GET_MORE_IMAGES = 'GET_MORE_IMAGES';
export const GET_FACEBOOK_CAMPAIGN_LANGUAGES = 'GET_FACEBOOK_CAMPAIGN_LANGUAGES';
export const INIT_USER = 'LOAD_USER';
export const INVITE_USER = 'INVITE_USER';
export const DATA_INSTAGRAM_ACCOUNT = 'DATA_INSTAGRAM_ACCOUNT';
export const LOG_MESSAGE = 'LOG_MESSAGE';

export const GET_FACEBOOK_PAGES = 'GET_FACEBOOK_PAGES';
export const DATA_FACEBOOK_PAGES_ITEMS = 'DATA_FACEBOOK_PAGES_ITEMS';
export const DATA_FACEBOOK_PAGES_LOADING = 'DATA_FACEBOOK_PAGES_LOADING';
export const SHOW_FACEBOOK_ANTI_SCUM_MODAL = 'SHOW_FACEBOOK_ANTI_SCUM_MODAL';
export const CONTINUE_FACEBOOK_ANTI_SCUM = 'CONTINUE_FACEBOOK_ANTI_SCUM';
export const SHOW_FACEBOOK_PAGE_SELECT_MODAL = 'SHOW_FACEBOOK_PAGE_SELECT_MODAL';
export const FACEBOOK_PAGE_SELECT = 'FACEBOOK_PAGE_SELECT';
export const AD_ACCOUNT_SELECT = 'AD_ACCOUNT_SELECT';
export const SHOW_FACEBOOK_AD_ACCOUNT_SELECT_MODAL = 'SHOW_FACEBOOK_AD_ACCOUNT_SELECT_MODAL';
export const SHOW_ACCOUNT_ISSUES_MODAL = 'SHOW_ACCOUNT_ISSUES_MODAL';

export const EDIT_BUSINESS_NAME = 'EDIT_BUSINESS_NAME';
export const SHOW_EDIT_BUSINESS_NAME_MODAL = 'SHOW_EDIT_BUSINESS_NAME_MODAL';
export const CONFIRM_EDIT_BUSINESS_NAME = 'CONFIRM_EDIT_BUSINESS_NAME';
export const EDIT_STORE_NAME = 'EDIT_STORE_NAME';
export const SET_STORE_STATUS = 'SET_STORE_STATUS';
export const ENABLE_DISABLE_STORE = 'ENABLE_DISABLE_STORE';
export const SHOW_EDIT_STORE_NAME_MODAL = 'SHOW_EDIT_STORE_NAME_MODAL';
export const CONFIRM_EDIT_STORE_NAME = 'CONFIRM_EDIT_STORE_NAME';

export const SHOW_ADD_BUSINESS_MODAL = 'SHOW_ADD_BUSINESS_MODAL';
export const SHOW_ADD_BUSINESS_CONFIRM_MODAL = 'SHOW_ADD_BUSINESS_CONFIRM_MODAL';
export const CONTINUE_ADD_BUSINESS = 'CONTINUE_ADD_BUSINESS';
export const ADD_BUSINESS_CONFIRM = 'ADD_BUSINESS_CONFIRM';
export const SHOW_ADD_STORE_MODAL = 'SHOW_ADD_STORE_MODAL';
export const ADD_STORE_CONFIRM_PAYMENT = 'ADD_STORE_CONFIRM_PAYMENT';
export const ADD_STORE_SELECT_PLACE = 'ADD_STORE_SELECT_PLACE';
export const MULTI_BUSINESS_ADD_BUSINESS = 'MULTI_BUSINESS_ADD_BUSINESS';

export const MULTI_STORE_ADD_STORE = 'MULTI_STORE_ADD_STORE';
export const MULTI_STORE_AD_ACCOUNT = 'MULTI_STORE_AD_ACCOUNT';
export const MULTI_STORE_BUSINESS = 'MULTI_STORE_BUSINESS';
export const MULTI_STORE_EXIT = 'MULTI_STORE_EXIT';
export const MULTI_STORE_UPDATE_PAYMENT = 'MULTI_STORE_UPDATE_PAYMENT';

export const OPEN_SUGGESTED_CAMPAIGN_DETAIL = 'OPEN_SUGGESTED_CAMPAIGN_DETAIL';
export const SAVE_SUGGESTED_CAMPAIGN = 'SAVE_SUGGESTED_CAMPAIGN';

export const OPEN_CAMPAIGN_WIZARD = 'OPEN_CAMPAIGN_WIZARD';
export const SEARCH_IMAGES = 'SEARCH_IMAGES';
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';
export const SHOW_FACEBOOK_CONNECT_BUSINESS_MANAGER_MODAL = 'SHOW_FACEBOOK_CONNECT_BUSINESS_MANAGER_MODAL';
export const SHOW_FACEBOOK_CONNECT_AD_ACCOUNT_MODAL = 'SHOW_FACEBOOK_CONNECT_AD_ACCOUNT_MODAL';
export const SHOW_FACEBOOK_PERMISSIONS_MODAL = 'SHOW_FACEBOOK_PERMISSIONS_MODAL';
export const SHOW_INSTAGRAM_SELECT_MODAL = 'SHOW_INSTAGRAM_SELECT_MODAL';
export const SHOW_TOASTER = 'SHOW_TOASTER';
export const SUBMIT_CAMPAIGN_FORM = 'SUBMIT_CAMPAIGN_FORM';
export const UPDATE_BUSINESS = 'UPDATE_BUSINESS';
export const SAVE_CAMPAIGN_FORM = 'SAVE_CAMPAIGN_FORM';

export const REINVITE_USER = 'REINVITE_USER';
export const REMOVE_USER = 'REMOVE_USER';

export const STRIPE_DEFAULT_PRICE = 14900;
export const ADD_STRIPE_PAYMENT_METHOD = 'ADD_STRIPE_PAYMENT_METHOD';
export const BILLING_MODAL_STRIPE_EXIT = 'BILLING_MODAL_STRIPE_EXIT';
export const BILLING_MODAL_STRIPE_SUBSCRIBE = 'BILLING_MODAL_STRIPE_SUBSCRIBE';
export const CANCEL_STRIPE_SUBSCRIPTION = 'CANCEL_STRIPE_SUBSCRIPTION';
export const DELETE_STRIPE_SUBSCRIPTION_ITEM = 'DELETE_STRIPE_SUBSCRIPTION_ITEM';
export const CREATE_STRIPE_PAYMENT_METHOD = 'CREATE_STRIPE_PAYMENT_METHOD';
export const DATA_BILLING_MODAL_STAGE = 'DATA_BILLING_MODAL_STAGE';
export const DATA_FACEBOOK_PAYMENT_METHOD = 'DATA_FACEBOOK_PAYMENT_METHOD';
export const DATA_SUBSCRIPTION = 'DATA_SUBSCRIPTION';
export const DATA_BILLING_SUBSCRIPTION_LOADING = 'DATA_BILLING_SUBSCRIPTION_LOADING';
export const DATA_SHOW_BILLING_MODAL = 'DATA_SHOW_BILLING_MODAL';
export const REACTIVATE_STRIPE_SUBSCRIPTION = 'REACTIVATE_STRIPE_SUBSCRIPTION';
export const UNPAUSE_STRIPE_COLLECTION = 'UNPAUSE_STRIPE_COLLECTION';
export const REACTIVATE_USER = 'REACTIVATE_USER';
export const UPDATE_STRIPE_PAYMENT_METHOD = 'UPDATE_STRIPE_PAYMENT_METHOD';
export const DATA_SHOW_REACTIVATE_SUBSCRIPTION_MODAL = 'DATA_SHOW_REACTIVATE_SUBSCRIPTION_MODAL';
export const HANDLE_CANCELLED_SUBSCRIPTION = 'HANDLE_CANCELLED_SUBSCRIPTION';
export const UPDATE_STRIPE_COLLECTION = 'UPDATE_STRIPE_COLLECTION';

export const BUSINESS_STORES_TAB = 'stores';
export const BUSINESS_INSIGHTS_TAB = 'insights';

export const BILLING_TAB = 'billing';
export const PROFILE_TAB = 'profile';
export const USERS_TAB = 'users';
export const INTEGRATIONS_TAB = 'integrations';

export const LIVE_TAB = 'live';
export const UPCOMING_TAB = 'upcoming';
export const DRAFT_TAB = 'draft';
export const HISTORY_TAB = 'history';
export const ERRORS_TAB = 'errors';

export const CAMPAIGN_DRAFT = 'draft';
export const CAMPAIGN_SUBMITTED = 'submitted';
export const CAMPAIGN_SUBMITTED_WITH_ISSUES = 'with-issues';
export const CAMPAIGN_SUBMITTED_FAILED = 'failed';

export const DATA_SMARTFEED_ITEMS = 'DATA_SMARTFEED_ITEMS';
export const DATA_SMARTFEED_LOADING = 'DATA_SMARTFEED_LOADING';
export const GET_SMARTFEED = 'GET_SMARTFEED';
export const SMARTFEED_ACTION_APPROVED = 'SMARTFEED_ACTION_APPROVED';
export const SMARTFEED_ACTION_APPROVED_DRAFT = 'SMARTFEED_ACTION_APPROVED_DRAFT';
export const SMARTFEED_ACTION_REJECTED = 'SMARTFEED_ACTION_REJECTED';
export const SMARTFEED_TYPE_INSIGHT = 'SMARTFEED_TYPE_INSIGHT';
export const SMARTFEED_TYPE_SUGGESTED_CAMPAIGN = 'SMARTFEED_TYPE_SUGGESTED_CAMPAIGN';
export const SMARTFEED_TYPE_SUGGESTED_CAMPAIGN_CARD = 'SMARTFEED_TYPE_SUGGESTED_CAMPAIGN_CARD';

// Marketing Suggested Campaigns
export const PUBLISH_MARKETING_AD = 'PUBLISH_MARKETING_AD';
export const MARKETING_AD_SHOW_FEEDBACK_DIALOG = 'MARKETING_AD_SHOW_FEEDBACK_DIALOG';
export const MARKETING_AD_SUBMIT_FEEDBACK = 'MARKETING_AD_SUBMIT_FEEDBACK';
export const UPDATE_MARKETING_SUGGESTED_CAMPAIGN = 'UPDATE_MARKETING_SUGGESTED_CAMPAIGN';

export const CLOSE_FEEDBACK_DIALOG = 'CLOSE_FEEDBACK_DIALOG';
export const SHOW_FEEDBACK_DIALOG = 'SHOW_FEEDBACK_DIALOG';
export const HANDLE_FEEDBACK_DIALOG = 'HANDLE_FEEDBACK_DIALOG';

export const SUBMIT_FEEDBACK = 'SUBMIT_FEEDBACK';
export const SUBMIT_USER_REGISTRATION = 'SUBMIT_USER_REGISTRATION';

export const DATA_LOADING_ASYNC = 'DATA_LOADING_ASYNC';
export const DATA_LOADING_PERMISSIONS = 'DATA_LOADING_PERMISSIONS';
export const DATA_CONFIRMATION_DIALOG = 'DATA_CONFIRMATION_DIALOG';
export const SHOW_CONFIRMATION_DIALOG = 'SHOW_CONFIRMATION_DIALOG';
export const RESULT_CONFIRMATION_DIALOG = 'RESULT_CONFIRMATION_DIALOG';

export const DATA_PUBLISH_MARKETING_DIALOG = 'DATA_PUBLISH_MARKETING_DIALOG';
export const SHOW_PUBLISH_MARKETING_DIALOG = 'SHOW_PUBLISH_MARKETING_DIALOG';
export const RESULT_PUBLISH_MARKETING_DIALOG = 'RESULT_PUBLISH_MARKETING_DIALOG';

export const RESET_APP = 'RESET_APP';

export const REPORTS_LOADING = 'REPORTS_LOADING';
export const DATA_CAMPAIGN_REPORT = 'DATA_CAMPAIGN_REPORT';
export const DATA_CAMPAIGN_REPORTS = 'DATA_CAMPAIGN_REPORTS';

export const DATA_STORES_PAYMENT_METHODS = 'DATA_STORES_PAYMENT_METHODS';
export const ADD_PAYMENT_METHOD_TO_ACCOUNT = 'ADD_PAYMENT_METHOD_TO_ACCOUNT';

export const GET_URL_PRESETS = 'GET_URL_PRESETS';
export const DATA_URL_PRESETS = 'DATA_URL_PRESETS';
export const DATA_URL_PRESETS_LOADING = 'DATA_URL_PRESETS_LOADING';
export const SAVE_URL_PRESETS = 'SAVE_URL_PRESETS';
export const DELETE_URL_PRESET = 'DELETE_URL_PRESET';

export const DATA_AD_CARD_PRESETS = 'DATA_AD_CARD_PRESETS';

export const DATA_STORES_CUSTOM_AUDIENCES = 'DATA_STORES_CUSTOM_AUDIENCES';

export const DATA_CAMPAIGN_PIXEL_EVENT_TYPE = 'DATA_CAMPAIGN_PIXEL_EVENT_TYPE';
export const DATA_CAMPAIGN_OPTIMIZATION_GOAL = 'DATA_CAMPAIGN_OPTIMIZATION_GOAL';

export const DATA_CAMPAIGN_AD_TYPE = 'DATA_CAMPAIGN_AD_TYPE';
export const DATA_CAMPAIGN_EVENT_ID = 'DATA_CAMPAIGN_EVENT_ID';
export const DATA_SUGGESTED_CAMPAIGN_EVENT_ID = 'DATA_SUGGESTED_CAMPAIGN_EVENT_ID';

export const BUSINESS_PAST_SUGGESTIONS_TAB = 'past suggestions';
export const BUSINESS_RICH_PROFILE_TAB = 'Rich Profile';

export const DATA_BUSINESS_SUGGESTED_CAMPAIGNS = 'DATA_BUSINESS_SUGGESTED_CAMPAIGNS';
export const DATA_BUSINESS_SUGGESTED_CAMPAIGNS_LOADING = 'DATA_BUSINESS_SUGGESTED_CAMPAIGNS_LOADING';
export const GET_BUSINESS_SUGGESTED_CAMPAIGNS = 'GET_BUSINESS_SUGGESTED_CAMPAIGNS';

export const GET_INVOICE_HISTORY = 'GET_INVOICE_HISTORY';
export const DATA_INVOICE_HISTORY = 'DATA_INVOICE_HISTORY';
export const DATA_INVOICE_HISTORY_LOADING = 'DATA_INVOICE_HISTORY_LOADING';

export const DATA_CAMPAIGN_OVERRIDE_AUTO_AGING = 'DATA_CAMPAIGN_OVERRIDE_AUTO_AGING';

export const CREATE_ACTIVITY_LOG = 'CREATE_ACTIVITY_LOG';

export const SUBSCRIPTION_CANCELLATION = 'SUBSCRIPTION_CANCELLATION';
export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION';
export const REMOVE_ITEMS = 'REMOVE_ITEMS';

export const LEGACY_CAMPAIGN = 'LEGACY_CAMPAIGN';
export const CAMPAIGN_STATUS_SMARTFEED = 'smartFeed';
export const CAMPAIGN_STATUS_SUBMITTED = 'submitted';
export const CAMPAIGN_STATUS_LIVE = 'live';
export const CAMPAIGN_STATUS_PAUSED = 'paused';

export const SEND_MAIL_CHIMP_TEST_EMAIL = 'SEND_MAIL_CHIMP_TEST_EMAIL';

export const DATA_GOOGLE_AD_METRICS = 'DATA_GOOGLE_AD_METRICS';
export const DATA_GOOGLE_AD_METRICS_LOADING = 'DATA_GOOGLE_AD_METRICS_LOADING';
export const DATA_GOOGLE_AD_METRICS_LOADING_ERROR = 'DATA_GOOGLE_AD_METRICS_LOADING_ERROR';

export const DATA_GOOGLE_PROMOTIONS_BY_MARKETING_CHANNEL_ID = 'DATA_GOOGLE_PROMOTIONS_BY_MARKETING_CHANNEL_ID';
export const DATA_GOOGLE_SITELINKS_BY_MARKETING_CHANNEL_ID = 'DATA_GOOGLE_SITELINKS_BY_MARKETING_CHANNEL_ID';
export const GET_GOOGLE_ASSETS = 'GET_GOOGLE_ASSETS';

export const DATA_LOADING_CAMPAIGNS = 'DATA_LOADING_CAMPAIGNS';

export const DATA_BELL_NOTIFICATIONS = 'DATA_BELL_NOTIFICATIONS';
export const UPDATE_ACTIVITY_LOG = 'UPDATE_ACTIVITY_LOG';

// Silectis
export const GET_SILECTIS_FACEBOOK_METRICS = 'GET_SILECTIS_FACEBOOK_METRICS';
export const GET_SILECTIS_GOOGLE_METRICS = 'GET_SILECTIS_GOOGLE_METRICS';
export const GET_SILECTIS_MAILCHIMP_METRICS = 'GET_SILECTIS_MAILCHIMP_METRICS';

// Marketing Channels
export const MARKETING_CHANNELS_LOADING = 'MARKETING_CHANNELS_LOADING';
export const DATA_MARKETING_CHANNELS = 'DATA_MARKETING_CHANNELS';
export const GET_MARKETING_CHANNELS = 'GET_MARKETING_CHANNELS';

// Google Search Ad Combinations
export const DATA_GOOGLE_SEARCH_AD_COMBINATIONS = 'DATA_GOOGLE_SEARCH_AD_COMBINATIONS';
export const DATA_GOOGLE_SEARCH_AD_COMBINATIONS_LOADING = 'DATA_GOOGLE_SEARCH_AD_COMBINATIONS_LOADING';
export const DATA_GOOGLE_SEARCH_AD_COMBINATIONS_LOADING_ERROR = 'DATA_GOOGLE_SEARCH_AD_COMBINATIONS_LOADING_ERROR';

export const ONBOARDING_META_ACTION = 'ONBOARDING_META_ACTION';

export const AUTHORIZE_MAILCHIMP = 'AUTHORIZE_MAILCHIMP';
export const PAUSE_MAILCHIMP_CAMPAIGN = 'PAUSE_MAILCHIMP_CAMPAIGN';
export const AUTHORIZE_GOOGLE = 'AUTHORIZE_GOOGLE';

// Google Detail Metrics
export const LIFETIME = 'lifetime';
export const TRENDS = 'trends';

export const AD_REQUEST = 'AD_REQUEST';

export const supressPermissionsCalls = true;

// LOCATIONS
export const SET_COORDINATES = 'SET_COORDINATES';
export const DATA_LOADING_COORDINATES = 'DATA_LOADING_COORDINATES';
export const DATA_COORDINATES = 'DATA_COORDINATES';

// COMMENTS
export const DATA_COMMENTS = 'DATA_COMMENTS';
export const DATA_LOADING_COMMENTS = 'DATA_LOADING_COMMENTS';
export const SET_COMMENTS = 'SET_COMMENTS';
export const POST_COMMMENT = 'POST_COMMMENT';

// ALL GEN FORMS
export const GET_ALL_LEAD_GEN_FORMS = 'GET_ALL_LEAD_GEN_FORMS';
